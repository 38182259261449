import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faUser, faTrophy, faHeart, faPencil, faDollarSign, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './DesignerRow.module.scss';
import SquaredImage from '../../SquaredImage/SquaredImage';
import { User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { setCommunityUser } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { cleanupHttpPath, locationStringForUser, numberVotes, registrationDateString } from '../../../helpers/utils';

interface IDesignerRow extends ICardInfo {
  circle?: boolean;
  contest?: number;
  reputation?: number;
  name?: string;
  role?: string;
  location: string;
  nameForURL?: string;
  userType?: string;
  startDate?: string;
  awarded: number;
  entries?: number;

  galleryImages: string[];
}
interface Props {
  user: User;
  galleryImages: string[];
}
const DesignerRowGallery = (props: { items: string[] }) => {
  let maxItems = props.items.slice(0, 5);
  let missingItems = 5 - maxItems.length;

  for (let i = 0; i < missingItems; i++) {
    maxItems.push('');
  }

  return (
    <>
      {maxItems.map((item, index) => (
        <div className={`${styles.designerRowGalleryItem} ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}>
          {item ? (
            <img src={item} alt="Gallery Item Cover" />
          ) : // <FontAwesomeIcon icon={faCircle as IconProp} className="color-light" />
          null}
        </div>
      ))}
    </>
  );
};

const DesignerRow: React.FC<Props> = ({user, galleryImages}) => {
  const redirect = useNavigate();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (user.profilePic) {
      setImage(cleanupHttpPath(user.profilePic));
    }
  }, [user]);

  const goToDetail = async () => {
    redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
  };
  return (
    <div className={`${styles.designerRowWrapper} col-12 mb-5  position-relative`}>
      <div className="row bg-light px-2">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${
              image ? styles.designerRowImage : styles.designerRowImagePlaceholder
            } flex-shrink-0 p-2`}
          >
            {image ? (
              <SquaredImage image={image} altText={user.username} circle={true} />
              // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
              ) : //<img src={props.image} alt="Project Cover" />
            null}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12 col-lg-4 d-flex flex-column justify-content-center ps-5 mt-4 mt-lg-0">
                <h2 className="text-xl fw-bold color-blue" onClick={() => goToDetail()}>
                  {user.username}
                </h2>
                <div className="text">{user.job?.name}</div>
              </div>

              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center px-2 ps-5 ps-lg-2 mt-5 mt-lg-0">
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start">{locationStringForUser(user)}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start">Since: {registrationDateString(user.registrationDate)}</div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{user.userType === 'D' ? 'User' : 'Client'}</div>
                </div>
              </div>

              {/* Project Stats */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center px-2 mt-5 mt-lg-0">
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">
                    <span className="fw-bold">{numberVotes(user.badgeDesignsOut)}</span> entries
                  </div>
                </div>
                {user.reputationCount && (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text text-start color-dark">
                      <span className="fw-bold">{user.reputationCount}</span> reputation
                    </div>
                  </div>
                )}
                {user.contestsCount && (
                    <div className="d-flex justify-content-start mb-3">
                      <div className="d-flex flex-column justify-content-center">
                        <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                      </div>
                      <div className="text text-start color-dark">
                        <span className="fw-bold">{user.contestsCount}</span> contest
                      </div>
                    </div>
                  )}
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faDollarSign as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-dark">
                    <span className="fw-bold">€ {user.badgeAmountIn}</span> awarded
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div
          className={`${styles.designerRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}
        >
          <DesignerRowGallery items={galleryImages} />
        </div>
      </div>
    </div>
  );
};

export default DesignerRow;
