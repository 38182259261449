import React, { FC, useEffect, useState } from 'react';
import styles from './YourProfile.module.scss';
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';
import DatePicker from '@amir04lm26/react-modern-calendar-date-picker';
import { startDateString } from '../../../helpers/utils';

interface Props {
  value: string;
  onChange: (newBirthdate: string) => void;
}

const BirthdateEditor: FC<Props> = ({ value, onChange }) => {
  const [birthDate, setBirthDate] = useState<any>();
  const formatInputValue = () => {
    if (!birthDate) return '';
    return `${birthDate.day}/${birthDate.month}/${birthDate.year}`;
  };

  useEffect(() => {
    if (value) {
      const initialDate = new Date(value);
      if (!isNaN(initialDate.getTime())) {
        setBirthDate({ month: initialDate.getMonth() + 1, day: initialDate.getDate(), year: initialDate.getFullYear() });
      }
    }
  }, [value]);

  const handleBirthDateChange = (date: any) => {
    setBirthDate(date);
    const dateObject = new Date(date.year, date.month - 1, date.day, 12);
    if (!isNaN(dateObject.getTime())) {
      console.log(dateObject);
      onChange(dateObject.toISOString());
    }
  };

  return (
    <div>
      <DatePicker
        value={birthDate}
        onChange={handleBirthDateChange}
        formatInputText={formatInputValue}
        inputPlaceholder={value ? startDateString(value) : 'Select your birthDate'}
        wrapperClassName={`col-12 `}
        inputClassName={`${styles.birthDateInput}`}
        colorPrimary="#7997c5"
      />
    </div>
  );
};

export default BirthdateEditor;
