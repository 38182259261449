import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { User } from '../../../global';
import styles from './Newsletter&Notification.module.scss';
import axiosHelper from '../../../helpers/axiosHelper';
interface Props {
  user: User;
  onChange: (user: User) => void;
}

const NewsletterNotifications: React.FC<Props> = ({ user, onChange }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },reset
  } = useForm<Partial<User>>({
    defaultValues: user, // Pre-fill form with the user's data
  });
  
  useEffect(() => {
    reset(user);
  }, [user, reset]);


  const onSubmit: SubmitHandler<Partial<User>> = async (data) => {
    const userdata = await axiosHelper.updateDSLUser(data);
    if (userdata) {
      onChange(userdata);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-5 mt-7">
        <div className="row mb-7 d-flex  ">
          <div className="col-12 col-md-2  mb-3  ">
            <span className="text fw-bold">Newsletter</span>
          </div>

          <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('receiveNewsletter')} />
            <span className="align-self-middle ps-5 ">Receive desall newsletter</span>
          </div>
        </div>
        <div className="row mb-7">
          <div className="col-12 col-md-2 mb-3 text fw-bold">General</div>

          <div className="col-12 mb-5 col-md-10 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasPrivateMessagesNotification')} />
            <span className="align-self-middle ps-5 ">Send and receive private messages</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasBadgeNotification')} />
            <span className="align-self-middle ps-5 ">Badge updates</span>
          </div>
        </div>
        <div className="row mb-7">
          <div className="col-12 col-md-2 mb-3 text fw-bold">Contest</div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasNewContestOnDesallNotification')} />
            <span className="align-self-middle ps-5 ">New contest on desall.com</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10  mb-3 d-flex flex-row justify-content-start align-items-center w-75 ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasContestStatusChangesNotification')} />
            <span className="align-self-middle ps-5 ">
              My favourite contests brief revisions and contests status changes <br /> (upload phase, vote phase, winner announcement)
            </span>
          </div>
        </div>
        <div className="row mb-7">
          <div className="col-12 col-md-2 mb-3 text fw-bold">Design</div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasNewCommentForMyDesignNotification')} />
            <span className="align-self-middle ps-5 ">New comments on my designs</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasRepliesOnMyCommentsNotification')} />
            <span className="align-self-middle ps-5 ">Replies on my comments</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasSomebodyFavoriteMyDesignNotification')} />
            <span className="align-self-middle ps-5 ">Somebody favourite my designs</span>
          </div>
        </div>
        <div className="row mb-7">
          <div className="col-12 col-md-2 mb-3 text fw-bold">Favourite</div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasMeAsFavouriteNotification')} />
            <span className="align-self-middle ps-5 ">Somebody saves me as favourite</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasNewDesignFromFavoriteNotification')} />
            <span className="align-self-middle ps-5 ">My favourite designers upload a new design</span>
          </div>
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-10 d-flex mb-5 flex-row justify-content-start align-items-center ">
            <input type="checkbox" className="flex-shrink-none" {...register('hasNewEntryForFavoriteNotification')} />
            <span className="align-self-middle ps-5 ">
              My favourite contests receive a new entry <br />
              (during the ending phase you may receive a lot of notifications)
            </span>
          </div>
        </div>
        <div className="row  d-flex justify-content-center align-items-center mb-7">
          <div className="row justify-content-center ">
            <button className={`btn-success rounded col-12 col-md-4 ${styles.button}  `}>update preferences</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewsletterNotifications;
