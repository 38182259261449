import React, { useEffect, useState } from 'react';
import styles from './ProfileHeader.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import HeaderUpload from './FileUpload/HeaderUpload';
import { mutate } from 'swr';

interface IProfileHeaderWLF {
  user: User;
  editable?: boolean;
}

export const ProfileHeaderWLF: React.FC<IProfileHeaderWLF> = ({ user, editable }) => {
  const [imageURL, setImageURL] = useState<string>('');

  useEffect(() => {
    return () => {
      if (imageURL && imageURL.startsWith('blob:')) {
        console.log('cleanup was needed');
        URL.revokeObjectURL(imageURL);
      }
    };
  }, [imageURL]);

  useEffect(() => {
    if (user.bannerAttachment) {
      setImageURL(user.bannerAttachment.s3Path || '');
    }
  }, [user]);

  const removeClicked = (e: any) => {
    e.preventDefault();
    console.log('remove clicked');
    axiosHelper.removeUserBanner(user.id).then((response) => {
      console.log(response);
      mutate('user|' + user.username, { ...user, bannerAttachment: undefined }, { revalidate: false }); // Update localStorage cache
      setImageURL('');
    });
  };

  const handleUploadFinished = (uuid: string, url: string) => {
    console.log('handleUploadFinished');
    console.log(uuid);
    console.log(url);
    setImageURL(url);
    axiosHelper.updateUserImages(uuid, user.id).then((response) => {
      if (response) {
        mutate('user|' + user.username, response, { revalidate: false }); // Update localStorage cache
      }
    });
  };

  // const uploadedImage = useRef('');
  // const currentUser = useStoreState(DslStore, getCurrentUser);
  // const uuidImage = useRef<string>('');

  const handleImageAdd = (e: any) => {
    // if (props.onImageAdd) {
    //   console.log('aggiunto banner');
    //   console.log(e);
    //   // return props.onImageAdd(image.webPath);
    // } else return false;
  };

  // const handleUuidPresigned = (e: any) => {
  //   console.log('uuid: ' + e);
  //   uuidImage.current = e;
  //   setTimeout(async () => {
  //     if (currentUser?.userType === 'D') {
  //       await axiosHelper.updateDesignerImages(e, currentUser?.id)?.then((response) => {
  //         console.log(response);
  //         // setCurrentUser(response);
  //         setCommunityUser(response);
  //         if (response.bannerAttachment) uploadedImage.current = response.bannerAttachment.s3Path;
  //         // props.onImageAdd && props.onImageAdd(e);
  //       });
  //     } else if (currentUser?.userType === 'C') {
  //       await axiosHelper.updateClientImages(e, currentUser?.id)?.then((response) => {
  //         // console.log(response);
  //         setCurrentUser(response);
  //         setCommunityUser(response);
  //         if (response.bannerAttachment) uploadedImage.current = response.bannerAttachment.s3Path;
  //         // props.onImageAdd && props.onImageAdd(e);
  //       });
  //     }
  //   }, 1500);
  // };

  // const handleImageRemove = async () => {
  //   if (currentUser?.userType === 'D') {
  //     await axiosHelper.removeBannerDesigner(currentUser?.id)?.then((response) => {
  //       console.log(response);
  //       setCurrentUser(response);
  //       setCommunityUser(response);
  //       uploadedImage.current = '';
  //     });

  //     // props.onImageRemove && props.onImageRemove();
  //   } else if (currentUser?.userType === 'C') {
  //     await axiosHelper.removeBannerClient(currentUser?.id)?.then((response) => {
  //       console.log(response);
  //       setCurrentUser(response);
  //       uploadedImage.current = '';
  //     });

  //     // props.onImageRemove && props.onImageRemove();
  //   }
  // };

  const AddArea = () => {
    return (
      <div className="w-100 h-100">
        <HeaderUpload uploadFinished={handleUploadFinished} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-danger`} onClick={removeClicked}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  if (!editable && !imageURL) {
    return null;
  }
  return (
    <div
      className={`${styles.profileHeaderWrapper} container px-0`}
      style={{
        // minHeight: `${props.minHeight ?? '25rem'}`,
        // backgroundImage: `${gradientString} url(${(imagePath)})`,
        backgroundColor: `transparent`,
      }}
    >
      <div className={` ${styles.profileHeaderImageContainer}  container d-flex flex-row  justify-content-center align-items-center position-relative px-0 `}>
        {imageURL && <img src={imageURL} alt="" />}
        {!imageURL && editable && <AddArea />}
        {imageURL && editable && <RemoveButton />}
      </div>
    </div>
  );
};
