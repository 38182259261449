import { useStoreState } from 'pullstate';
import DslStore, { setCustomUsers } from '../../store/DslStore';
import { getCurrentUser, getCustomUsers } from '../../store/Selectors';
import { CustomCommunityProps, User } from '../../global';
import DesignerSquare from './DesignerSquare/DesignerSquare';
import { fullPathLogoCommunity } from '../../helpers/utils';
import { useEffect } from 'react';
import axiosHelper from '../../helpers/axiosHelper';
import Skeleton from '@mui/material/Skeleton';

function Custom4Users(props: CustomCommunityProps) {
  const users = useStoreState(DslStore, getCustomUsers);

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers();
    }
  });

  const fetchUsers = async () => {
    if (props.username1 && props.username2 && props.username3 && props.username4) {
      await axiosHelper
        .retrieveCustom4Users(props.username1, props.username2, props.username3, props.username4)
        .then((response) => {
          setCustomUsers(response);
        });
    }
  };

  return (
    <section className="container py-5  ">
      <div className="row color-danger">
        <div className="col-12">
          {/* <CommunityNavigation onChangeLayout={setLayoutCommunity} /> */}
          <h2 className="fw-bold color-dark px-3 mb-3">{props.title} </h2>
        </div>
      </div>
      <div className="row  mb-5 ">
        {users.length > 0 ? (
          users?.map((aUser: User, index) => (
            <div key={index} className="col-12 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center">
              <DesignerSquare
                user={aUser}
              />
            </div>
          ))
        ) : (
          <>
            <div className=" col-12 d-flex flex-row mt-3 mb-4">
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Custom4Users;
