import { createAsyncAction, Store, successResult } from 'pullstate';

import {
  Client,
  Contest,
  Award,
  User,
  DesignDetails,
  ContestDesign,
  UserDesigns,
  ContestInspirations,
  UserInspiration,
  DesignsILike,
  ContestsILike,
  UsersILike,
  DesignLogo,
  FooterData,
  MenuData,
  StrapiPageData,
  ContestTag,
  CoCreationContest,
  CoCreationDesign,
  CoCreationContestStats,
  UpdateUserData,
  DSLTag,
  ItemLiked,
  InspirationVotes,
  inspirationVote,
  UploadContestUserData,
  HunterRequest,
  CommunityStrapiComponent,
  ContestCategory,
  SocialUser,
  AccessCode,
  ContestGuideline,
  ContestInspirationToEdit,
  GalleryDesign,
  UserBadge,
  ContestSearchParams,
  FilterBy,
  SortBy,
  ContestByName,
  ContestMini,
} from '../global';

import axiosHelper from '../helpers/axiosHelper';

export interface IDslStore {
  mobileMode: boolean;
  contestSearchParams: ContestSearchParams;
  contestsByName: ContestByName[];
  currentUser?: User;
  contests: Contest[];
  designSearchError: string;
  contestsFiltered: Contest[];
  clientContests: Contest[];
  currentContest?: Contest;
  allCoCreationContests: CoCreationContest[];
  cocreationContests: CoCreationContest[];
  contestCategories: ContestCategory[];
  cocreationContestStats?: CoCreationContestStats;
  contestTimelineTotUpload: number;
  contestTimelinePassedUpload: number;
  contestTimelineTotCommunityVote: number;
  contestTimelinePassedCommunityVote: number;
  contestTimelineTotClientVote: number;
  contestTimelinePassedClientVote: number;
  timerHours: number;
  timerMinutes: number;
  timerSeconds: number;
  clientContestNumber: number;
  layoutContests: number;
  layoutCommunity: number;
  layoutInspirations: number;
  layoutGallery: number;
  layoutClientContest: number;
  layoutPortfolio: number;
  layoutContestPortfolio: number;
  contestPageNumber: number;
  communityPageNumber: number;
  userSearchPageNumber: number;
  contestSearchPageNumber: number;
  designSearchPageNumber: number;
  contestInspirationsPageNumber: number;
  userInspirationsPageNumber: number;
  galleryPageNumber: number;
  clientContestPageNumber: number;
  portfolioPageNumber: number;
  contestPortfolioPageNumber: number;
  userFavoritesPageNumber: number;
  community: User[];
  communityCopy: User[];
  communityPageNumberLimit: number;
  topOfAllTimes: User[];
  topOfTheWeek: User[];
  lastUsers: User[];
  customUsers: User[];
  communityUser?: User;
  contestUser?: Client;
  contestNumber: number;
  communityNumber: number;
  navLinkId: FooterData;
  contestInspirations: ContestInspirations[];
  contestInspirationsCopy: ContestInspirations[];
  contestAwards: Award[];
  cocreationContestAward: Award[];
  contestTags: ContestTag[];
  contestDesign?: ContestDesign;
  portfolioDesign: UserDesigns[];
  portfolioDesignCopy: UserDesigns[];
  userInspirations: UserInspiration[];
  userInspirationsCopy: UserInspiration[];
  userDesignsFavorites: DesignsILike[];
  userDesignsFavoritesCopy: DesignsILike[];
  userInspirationsFavorites: InspirationVotes[];
  userInspirationsFavoritesCopy: InspirationVotes[];
  inspirationsContestName: string[];
  userContestsFavorites: ContestsILike[];
  userContestsFavoritesCopy: ContestsILike[];
  userBadges: UserBadge[];
  userAwards: Award[];
  designCommentsVisibility: number;
  currentDesign?: DesignDetails;
  designCurrentImage?: string;
  userFavoritesType: number;
  cocreationContestGallery: GalleryDesign[];
  contestGalleryDesigns: GalleryDesign[];
  contestGalleryDesignsCopy: GalleryDesign[];
  footerdata: FooterData[];
  allMenus: MenuData[];
  footerMenus: MenuData[];
  headerMenus: MenuData[];
  hiddenMenus: MenuData[];
  menuSelected: MenuData;
  strapiPaths: string[];
  strapiMenus: string[];
  busy: number;
  strapiPage: StrapiPageData;
  strapiCommunity: CommunityStrapiComponent[];
  editProfile?: UpdateUserData;
  uploadContestEditProfile?: UploadContestUserData;
  tags: DSLTag[];
  inspitationToEdit?: UserInspiration;
  contestInspirationToEdit: ContestInspirationToEdit;
  socialUser: SocialUser;
  welcomeMessage: boolean;
  contestGuidelines: ContestGuideline[];
  contestAccessDenied: boolean;
  searchResultContest: Contest[];
  searchResultContestCopy: Contest[];
  searchResultContestFiltered: Contest[];
  searchResultDesign: GalleryDesign[];
  searchResultDesignCopy: GalleryDesign[];
  searchResultUser: User[];
  searchResultUserCopy: User[];
  userSearchPageNumberLimit: number;
  designSearchPageNumberLimit: number;
  contestSearchPageNumberLimit: number;
}

const DslStore = new Store<IDslStore>({
  mobileMode: false,
  contestsByName: [],
  contestSearchParams: {
    category: undefined,
    currentPage: 1,
    numberOfPages: 0,
    sortBy: 'date',
    orderBy: 'desc',
    filterBy: 'all',
  },
  currentUser: undefined,
  contests: [],
  contestsFiltered: [],
  designSearchError: '',
  clientContests: [],
  currentContest: undefined,
  allCoCreationContests: [],
  cocreationContests: [],
  cocreationContestStats: undefined,
  contestCategories: [],
  contestTimelineTotUpload: 0,
  contestTimelinePassedUpload: 0,
  contestTimelineTotCommunityVote: 0,
  contestTimelinePassedCommunityVote: 0,
  contestTimelineTotClientVote: 0,
  contestTimelinePassedClientVote: 0,
  timerHours: 0,
  timerMinutes: 0,
  timerSeconds: 0,
  clientContestNumber: 0,
  layoutContests: 1,
  layoutCommunity: 1,
  layoutInspirations: 1,
  layoutGallery: 1,
  layoutClientContest: 1,
  layoutPortfolio: 1,
  layoutContestPortfolio: 1,
  contestPageNumber: 1,
  communityPageNumber: 1,
  contestInspirationsPageNumber: 1,
  userInspirationsPageNumber: 1,
  galleryPageNumber: 1,
  clientContestPageNumber: 1,
  portfolioPageNumber: 1,
  contestPortfolioPageNumber: 1,
  userFavoritesPageNumber: 1,
  community: [],
  communityCopy: [],
  communityPageNumberLimit: 1,
  topOfAllTimes: [],
  topOfTheWeek: [],
  lastUsers: [],
  customUsers: [],
  communityUser: undefined,
  contestUser: undefined,
  contestNumber: 0,
  communityNumber: 0,
  navLinkId: { id: 0, text: 'Home', path: '/' },
  contestInspirations: [],
  contestInspirationsCopy: [],
  contestAwards: [],
  cocreationContestAward: [],
  contestTags: [],
  contestDesign: undefined,
  portfolioDesign: [],
  portfolioDesignCopy: [],
  userInspirations: [],
  userInspirationsCopy: [],
  userInspirationsFavorites: [],
  userInspirationsFavoritesCopy: [],
  inspirationsContestName: [],
  userDesignsFavorites: [],
  userContestsFavorites: [],
  userAwards: [],
  designCommentsVisibility: 0,
  currentDesign: undefined,
  designCurrentImage: undefined,
  userFavoritesType: 0,
  userBadges: [],
  userContestsFavoritesCopy: [],
  userDesignsFavoritesCopy: [],
  cocreationContestGallery: [],
  contestGalleryDesigns: [],
  contestGalleryDesignsCopy: [],
  footerdata: [],
  allMenus: [],
  footerMenus: [],
  headerMenus: [],
  hiddenMenus: [],
  strapiPaths: [],
  strapiMenus: [],
  strapiCommunity: [],
  menuSelected: { title: '', path: '', data: [] },
  busy: 1,
  strapiPage: {
    id: 0,
    attributes: { footertitle: '', headercolor: '', link: '', title: '', visible: true, contentblocks: [] },
  },
  editProfile: undefined,
  uploadContestEditProfile: undefined,
  tags: [],
  inspitationToEdit: undefined,
  contestInspirationToEdit: {
    id: 0,
    contest: {
      name: undefined,
      id: undefined,
    },
    isPrivate: false,
    user: {
      id: undefined,
      username: undefined,
    },
  },
  socialUser: { social: '', user: { uid: '' } },
  welcomeMessage: false,
  contestGuidelines: [],
  contestAccessDenied: false,
  searchResultContest: [],
  searchResultContestCopy: [],
  searchResultContestFiltered: [],
  searchResultDesign: [],
  searchResultDesignCopy: [],
  searchResultUser: [],
  searchResultUserCopy: [],
  userSearchPageNumber: 1,
  contestSearchPageNumber: 1,
  designSearchPageNumber: 1,
  userSearchPageNumberLimit: 1,
  designSearchPageNumberLimit: 1,
  contestSearchPageNumberLimit: 1,
});

export default DslStore;

export const setMobileMode = (value: boolean) => {
  DslStore.update((s) => {
    s.mobileMode = value;
  });
};
export const setContest = (contests: Contest[]) => {
  DslStore.update((s) => {
    s.contests = contests;
  });
};

export const setWelcomeMessage = (message: boolean) => {
  DslStore.update((s) => {
    s.welcomeMessage = message;
  });
};

export const setContestAccessDenied = (access: boolean) => {
  DslStore.update((s) => {
    s.contestAccessDenied = access;
  });
};

export const setDesignChargingError = (contestName: string) => {
  DslStore.update((s) => {
    s.designSearchError = contestName;
  });
};

export const setContestInspirationToEdit = (inspiration: ContestInspirationToEdit) => {
  DslStore.update((s) => {
    s.contestInspirationToEdit = inspiration;
  });
};

export const setContestSorted = (contestsFiltered: Contest[]) => {
  DslStore.update((s) => {
    s.contestsFiltered = contestsFiltered;
  });
};

export const startBusyMode = () => {
  DslStore.update((s) => {
    s.busy = s.busy + 1;
  });
};

export const setSocialUser = (socialUser: SocialUser) => {
  DslStore.update((s) => {
    s.socialUser = socialUser;
  });
};

export const setStrapiPage = (page: StrapiPageData) => {
  DslStore.update((s) => {
    s.strapiPage = page;
  });
};

export const setStrapiCommunity = (strapiCommunity: CommunityStrapiComponent[]) => {
  DslStore.update((s) => {
    s.strapiCommunity = strapiCommunity;
  });
};

export const endBusyMode = () => {
  DslStore.update((s) => {
    s.busy = s.busy - 1;
  });
};

export const setContestGuidelines = (guidelines: ContestGuideline[]) => {
  DslStore.update((s) => {
    s.contestGuidelines = guidelines;
  });
};

export const setClientContest = (contests: Contest[]) => {
  DslStore.update((s) => {
    s.clientContests = contests;
  });
};

export const setTimelineTotUpload = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelineTotUpload = days;
  });
};

export const setTimelinePassedUpload = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelinePassedUpload = days;
  });
};

export const setTimelineTotCommunityVote = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelineTotCommunityVote = days;
  });
};

export const setTimelinePassedCommunityVote = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelinePassedCommunityVote = days;
  });
};

export const setTimelineTotClientVote = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelineTotClientVote = days;
  });
};

export const setTimelinePassedClientVote = (days: number) => {
  DslStore.update((s) => {
    s.contestTimelinePassedClientVote = days;
  });
};

export const setTimerHours = (hours: number) => {
  DslStore.update((s) => {
    s.timerHours = hours;
  });
};

export const setTimerMinutes = (minutes: number) => {
  DslStore.update((s) => {
    s.timerMinutes = minutes;
  });
};

export const setTimerSeconds = (seconds: number) => {
  DslStore.update((s) => {
    s.timerSeconds = seconds;
  });
};

export const setCommunity = (community: User[]) => {
  const userPerPage = 20;
  DslStore.update((s) => {
    s.community = community;
    s.communityPageNumberLimit = Math.ceil(community.length / userPerPage);
  });
};

export const setCommunityCopy = (community: User[]) => {
  DslStore.update((s) => {
    s.communityCopy = community;
  });
};

export const setEditProfile = (data: UpdateUserData) => {
  DslStore.update((s) => {
    s.editProfile = data;
  });
};

export const setUploadContestEditProfile = (data: UploadContestUserData) => {
  DslStore.update((s) => {
    s.uploadContestEditProfile = data;
  });
};

export const setCoCreationContest = (contests: CoCreationContest[]) => {
  console.log(contests);
  let cocreationDesigns: CoCreationDesign[] = [];
  let cocreationAwards: Award[] = [];
  contests?.map(
    (item: CoCreationContest) => (
      item.awards.map((item: Award) => cocreationAwards.push(item)),
      item.publicDesignsOrdered.map((jtem: CoCreationDesign) => cocreationDesigns.push(jtem))
    )
  );
  cocreationAwards.length > 0 && setCoCreationContestAward(cocreationAwards);
  DslStore.update((s) => {
    s.cocreationContests = contests;
  });
};

export const setCoCreationContestStats = (contestStats: CoCreationContestStats) => {
  DslStore.update((s) => {
    s.cocreationContestStats = contestStats;
  });
};

export const setUserBadges = (userBadges: UserBadge[]) => {
  console.log(userBadges);
  DslStore.update((s) => {
    s.userBadges = userBadges;
  });
};

export const setContestCategories = (contestCategories: ContestCategory[]) => {
  DslStore.update((s) => {
    s.contestCategories = contestCategories;
  });
};

export const setUserInspirationVotes = (inspirationVotes: inspirationVote[]) => {
  DslStore.update((s) => {
    if (s.currentUser !== undefined) {
      s.currentUser.inspirationVotes = inspirationVotes;
    }
  });
};

export const setCurrentContest = (contest: Contest) => {
  // console.log('contestID', contest.id);
  setContestGuidelines([]);
  console.log('setCurrentContest', contest);
  console.log('WOLF REMOVED STUFF');
  // axiosHelper.getContestGuidelines(contest.id).then((response) => {
  //   setContestGuidelines(response);
  // });
  // axiosHelper.getContestInspirations(contest.id)?.then((response) => {
  //   // console.log(response.allMainInspirationsOrdered);
  //   setContestInspirations(response.inspirations);
  //   setContestInspirationsCopy(response.inspirations);
  // });
  // axiosHelper.getContestAwards(contest.id)?.then((response) => {
  //   // console.log(response.awards);
  //   setContestAwards(response.awards);
  // });
  // axiosHelper.getContestGallery(contest.id)?.then((response) => {
  //   setContestGallery(response);
  //   setContestGalleryCopy(response);
  // });

  DslStore.update((s) => {
    s.currentContest = contest;
  });
};

export const setFooterData = (footerdata: FooterData[]) => {
  // console.log(footerdata);
  DslStore.update((s) => {
    s.footerdata = footerdata;
  });
};

export const setContestInspirations = (inspirations: ContestInspirations[]) => {
  // console.log(inspirations);
  DslStore.update((s) => {
    s.contestInspirations = inspirations;
  });
};

export const setContestInspirationsCopy = (inspirations: ContestInspirations[]) => {
  DslStore.update((s) => {
    s.contestInspirationsCopy = inspirations;
  });
};

export const setFavoriteDesigns = (userDesignsFavorites: DesignsILike[]) => {
  // console.log(userDesignsFavorites);
  DslStore.update((s) => {
    s.userDesignsFavorites = userDesignsFavorites;
  });
};

export const setFavoriteDesignsCopy = (userDesignsFavorites: DesignsILike[]) => {
  // console.log(userDesignsFavorites);
  DslStore.update((s) => {
    s.userDesignsFavoritesCopy = userDesignsFavorites;
  });
};

export const setFavoriteInspirations = (userInspirationsFavorites: InspirationVotes[]) => {
  DslStore.update((s) => {
    let newNames: string[] = s.inspirationsContestName;
    userInspirationsFavorites.map((item) => {
      if (item.inspiration.contest?.name) {
        if (!newNames.includes(item.inspiration.contest?.name)) {
          console.log('aggiungo: ' + item.inspiration.contest?.name);
          newNames.push(item.inspiration.contest.name);
        }
      }
    });
    console.log(newNames);
    s.userInspirationsFavorites = userInspirationsFavorites;
    s.inspirationsContestName = newNames;
  });
};

export const setFavoriteInspirationsCopy = (userInspirationsFavorites: InspirationVotes[]) => {
  console.log(userInspirationsFavorites);
  DslStore.update((s) => {
    s.userInspirationsFavoritesCopy = userInspirationsFavorites;
  });
};

export const setFavoriteContests = (userContestsFavorites: ContestsILike[]) => {
  // console.log(userContestsFavorites);
  DslStore.update((s) => {
    s.userContestsFavorites = userContestsFavorites;
  });
};

export const setFavoriteContestsCopy = (userContestsFavorites: ContestsILike[]) => {
  // console.log(userContestsFavorites);
  DslStore.update((s) => {
    s.userContestsFavoritesCopy = userContestsFavorites;
  });
};

// export const setFavoriteUsers = (userUsersFavorites: UsersILike[]) => {
//   // console.log(userContestsFavorites);
//   DslStore.update((s) => {
//     s.userUsersFavorites = userUsersFavorites;
//   });
// };

export const setUserIlike = (userILike: ItemLiked[]) => {
  console.log(userILike);
  DslStore.update((s) => {
    if (s.currentUser) {
      s.currentUser.usersILike = userILike;
    }
  });
};

// export const setFavoriteUsersCopy = (userUsersFavorites: UsersILike[]) => {
//   // console.log(userContestsFavorites);
//   DslStore.update((s) => {
//     s.userUsersFavoritesCopy = userUsersFavorites;
//   });
// };

export const setInspirationsContestName = (names: string[]) => {
  console.log(names);
  DslStore.update((s) => {
    s.inspirationsContestName = names;
  });
};

export const setUserInspirations = (inspirations: UserInspiration[]) => {
  DslStore.update((s) => {
    s.userInspirations = inspirations;
  });
};

export const setUserInspirationsCopy = (inspirations: UserInspiration[]) => {
  console.log(inspirations);
  DslStore.update((s) => {
    s.inspirationsContestName = [];
    let newNames: string[] = s.inspirationsContestName;
    inspirations.map((item) => {
      if (item.contest?.name) {
        if (!newNames.includes(item.contest?.name)) {
          console.log('aggiungo: ' + item.contest?.name);
          newNames.push(item.contest.name);
        }
      }
    });
    console.log(newNames);
    s.userInspirationsCopy = inspirations;
    s.inspirationsContestName = newNames;
  });
};

export const addLikeToInspiration = (inspirationId: number) => {
  console.log(inspirationId);
  DslStore.update((s) => {
    s.userInspirations.map((item) => (item.id === inspirationId && item.votesCount ? item.votesCount + 1 : null));
  });
};

export const setInsrpirationToEdit = (inspiration?: UserInspiration) => {
  DslStore.update((s) => {
    s.inspitationToEdit = inspiration;
  });
};

export const setDesignCurrentImage = (path: string) => {
  // console.log(path);
  DslStore.update((s) => {
    s.designCurrentImage = path;
  });
};

export const setStrapiPath = (paths: string[]) => {
  DslStore.update((s) => {
    // console.log(paths);
    s.strapiPaths = paths;
  });
};

export const setContestAwards = (contestAward: Award[]) => {
  // console.log(contestAward);
  DslStore.update((s) => {
    s.contestAwards = contestAward;
  });
};

export const setContestTags = (contestTags: ContestTag[]) => {
  // console.log(contestTags);
  DslStore.update((s) => {
    s.contestTags = contestTags;
  });
};

export const setUserAwards = (userAward: Award[]) => {
  // console.log(contestAward);
  // console.log(userAward);
  DslStore.update((s) => {
    s.userAwards = userAward;
  });
};

export const setContestDesign = (contestDesign: ContestDesign) => {
  DslStore.update((s) => {
    s.contestDesign = contestDesign;
  });
};

export const setPortfolioDesign = (portfolioDesigns: UserDesigns[]) => {
  // console.log(portfolioDesigns);
  DslStore.update((s) => {
    s.portfolioDesign = portfolioDesigns;
  });
};

export const setPortfolioDesignCopy = (portfolioDesigns: UserDesigns[]) => {
  let correctCopy: UserDesigns[] = [];
  portfolioDesigns.map((item) => {
    if (!item.isCensored && !item.isHidden && item.isPublished && item.contest.name) {
      correctCopy.push(item);
    }
  });
  DslStore.update((s) => {
    s.portfolioDesignCopy = correctCopy;
  });
};

export const setLayoutContest = (number: number) => {
  console.log(number);
  DslStore.update((s) => {
    s.layoutContests = number;
  });
};

export const setClientContestNumber = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.clientContestNumber = number;
  });
};

export const setUserFavoritesPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.userFavoritesPageNumber = number;
  });
};

export const setLayoutCommunity = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutCommunity = number;
  });
};

export const setLayoutContestPortfolio = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutContestPortfolio = number;
  });
};

// export const setUserUserFavorited = (userFavorited: UserFavorited[]) => {
//   DslStore.update((s) => {
//     s.userUserFavorited = userFavorited;
//   });
// };

// export const setUserUserFavoritedCopy = (userFavorited: UserFavorited[]) => {
//   DslStore.update((s) => {
//     s.userUserFavoritedCopy = userFavorited;
//   });
// };

export const setLayoutInspirations = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutInspirations = number;
  });
};

export const setLayoutGallery = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutGallery = number;
  });
};

export const setLayoutClientContest = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutClientContest = number;
  });
};

export const setLayoutPortfolio = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.layoutPortfolio = number;
  });
};

export const setClientContestPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.clientContestPageNumber = number;
  });
};

export const setContestPortfolioPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.clientContestPageNumber = number;
  });
};

export const setGalleryPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.galleryPageNumber = number;
  });
};

export const setPortfolioPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.portfolioPageNumber = number;
  });
};

export const setUserFavoritesType = (number: number) => {
  // console.log(number);
  DslStore.update((s) => {
    s.userFavoritesType = number;
  });
};

export const setContestNumber = createAsyncAction(async () => {
  // console.log('FETCHING CONTEST NUMBER');

  await axiosHelper.counters().then((result) => {
    console.log('fetchContestNumber');
    if (result) {
      // console.log('contestNumber : ' + JSON.stringify(result.data.count));
      let number = result.ContestsCount;
      DslStore.update((s) => {
        s.contestNumber = number;
      });
    }
  });
  return successResult();
});

export const setFooterMenuSelected = (menuNumber: MenuData) => {
  DslStore.update((s) => {
    console.log(menuNumber);
    s.menuSelected = menuNumber;
  });
};

export const fetchMenus = createAsyncAction(async () => {
  let allDataFetced: MenuData[] = [];
  let footerDataFetched: MenuData[] = [];
  let headerDataFetched: MenuData[] = [];
  let menudDataFetched: MenuData[] = [];
  let strapiPath: string[] = [];

  await axiosHelper.fetchStrapiMenus().then((res) => {
    console.log(' fetchMenus');
    console.log(res.data);
    if (res.data) {
      res.data.map((item: any) => {
        // console.log('ao: ');
        // console.log(item.attributes.pages.data);
        if (item.attributes.type === 'footer') {
          strapiPath.push(item.attributes.path);

          // console.log(strapiPath);
          let pages: FooterData[] = [];
          item.attributes.pages.data.map((item: any, index: number) =>
            pages.push({ text: item.attributes.footertitle, path: item.attributes.link, id: index })
          );
          // console.log(pages);
          footerDataFetched.push({ title: item.attributes.title, path: item.attributes.path, data: pages });
          allDataFetced.push({ title: item.attributes.title, path: item.attributes.path, data: pages });
          // console.log(footerDataFetched);
        }
        if (item.attributes.type === 'header') {
          strapiPath.push(item.attributes.path);
          // console.log(strapiPath);
          let pages: FooterData[] = [];
          item.attributes.pages.data.map((item: any, index: number) =>
            pages.push({ text: item.attributes.footertitle, path: item.attributes.link, id: index })
          );
          // console.log(pages);
          headerDataFetched.push({ title: item.attributes.title, path: item.attributes.path, data: pages });
          // allDataFetced.push({ title: item.attributes.menutitle, path: item.attributes.path, data: pages });
          // console.log(headerDataFetched);
        }
        if (item.attributes.type === 'hidden') {
          strapiPath.push(item.attributes.path);
          // console.log(strapiPath);
          let pages: FooterData[] = [];
          item.attributes.pages.data.map((item: any, index: number) =>
            pages.push({ text: item.attributes.footertitle, path: item.attributes.link, id: index })
          );
          // console.log(pages);
          menudDataFetched.push({ title: item.attributes.title, path: item.attributes.path, data: pages });
          allDataFetced.push({ title: item.attributes.title, path: item.attributes.path, data: pages });
          // console.log(menudDataFetched);
        }
      });
    }
    // console.log(allDataFetced);
    setStrapiPath(strapiPath);
    DslStore.update((s) => {
      // console.log(headerDataFetched);
      // console.log(menudDataFetched);
      s.allMenus = allDataFetced;
      s.footerMenus = footerDataFetched;
      s.headerMenus = headerDataFetched;
      s.hiddenMenus = menudDataFetched;
    });
  });

  return successResult();
});

export const setContestPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.contestPageNumber = number;
  });
};

export const setContestInspirationsPageNumber = (number: number) => {
  console.log(number);
  DslStore.update((s) => {
    s.contestInspirationsPageNumber = number;
  });
};

export const setUserInspirationsPageNumber = (number: number) => {
  DslStore.update((s) => {
    s.userInspirationsPageNumber = number;
  });
};

export const setNavLinkActive = (navLink: FooterData) => {
  DslStore.update((s) => {
    // console.log(navLink);
    s.navLinkId = navLink;
  });
};

export const setUserImage = (image: string) => {
  DslStore.update((s) => {
    if (s.currentUser) {
      // s.currentUser.logoAttachment.s3Path = image;
    }
  });
};

export const setCategories = createAsyncAction(async () => {
  await axiosHelper.getContestCategories().then((response) => {
    console.log('fetcContestCategories');
    if (response) {
      DslStore.update((s) => {
        s.contestCategories = response;
      });
    }
  });
  return successResult();
});

export const setCounters = createAsyncAction(async () => {
  // console.log('FETCHING NUMBER OF USERS');

  await axiosHelper.counters().then((result) => {
    console.log(' fetchCommunityNumber');
    if (result) {
      let usersCount = result.UsersCount;
      let contestsCount = result.ContestsCount;

      // console.log('communityNumber : ' + JSON.stringify(result.data.count));
      DslStore.update((s) => {
        s.communityNumber = usersCount;
        s.contestNumber = contestsCount;
      });
    }
  });
  return successResult();
});

export const setCommunityPageNumber = async (number: number) => {
  DslStore.update((s) => {
    s.communityPageNumber = number;
  });
};

export const setCurrentUser = (user?: User) => {
  // console.log('setCurrentUser ricevo qualcosa');
  DslStore.update((s) => {
    // console.log(user);
    // if (user.hunters.length > 0) {
    s.currentUser = user;
  });
};

export const setContestsILike = (contestsILike: ItemLiked[]) => {
  DslStore.update((s) => {
    if (s.currentUser !== undefined) {
      s.currentUser.contestsILike = contestsILike;
    }
  });
};

export const setDesignsILike = (designsILike: ItemLiked[]) => {
  DslStore.update((s) => {
    if (s.currentUser !== undefined) {
      s.currentUser.designsILike = designsILike;
    }
  });
};

export const setCurrentUserHunterRequests = (hunterRequests: HunterRequest[]) => {
  DslStore.update((s) => {
    if (s.currentUser !== undefined) {
      s.currentUser.hunterRequests = hunterRequests;
    }
  });
};

export const setCommunityUser = (user?: User) => {
  console.log('setCommunityUser ricevo qualcosa');
  DslStore.update((s) => {
    s.communityUser = user;
    s.userDesignsFavoritesCopy = [];
    s.userDesignsFavorites = [];
    s.userAwards = [];
    s.userInspirations = [];
    s.portfolioDesign = [];
  });
};
export const fetchUsers = createAsyncAction(async () => {
  // console.log('FETCHING USERS');

  await axiosHelper.getUsers(1).then((result) => {
    console.log(' fetchUsers');
    if (result.data) {
      DslStore.update((s) => {
        s.community = result.data;
      });
    }
  });
  return successResult();
});

export const setCustomUsers = (users: User[]) => {
  DslStore.update((s) => {
    s.customUsers = users;
  });
};

export const setLastUsers = (users: User[]) => {
  DslStore.update((s) => {
    s.lastUsers = users;
  });
};

export const setTopUsers = (users: User[]) => {
  DslStore.update((s) => {
    s.topOfAllTimes = users;
  });
};

export const setTopUsersOfTheWeek = (topUsers: User[]) => {
  DslStore.update((s) => {
    s.topOfTheWeek = topUsers;
  });
};

export const fetchTags = createAsyncAction(async () => {
  await axiosHelper.getTags().then((result) => {
    console.log('fetchTags');
    if (result) {
      DslStore.update((s) => {
        s.tags = result;
      });
    }
  });
  return successResult();
});

export const fetchContest = createAsyncAction(async () => {
  await axiosHelper.getContests().then((result) => {
    console.log(' fetchContests');
    let contestDataOrdered: Contest[] = [];
    if (result.data) {
      console.log(result.data);
      contestDataOrdered = result.data.sort((a, b) => {
        return b.creationDate.localeCompare(a.creationDate);
      });

      DslStore.update((s) => {
        s.contests = contestDataOrdered;
        s.contestsFiltered = contestDataOrdered;
      });
    }
  });
  // console.log(result);
  return successResult();
});

export const fetchCoCreationContest = createAsyncAction(async () => {
  console.log('fetchCocreationContest');
  await axiosHelper.indexCoCreationContests().then((result) => {
    console.log('cocreation index');
    if (result) {
      console.log(result);
      DslStore.update((s) => {
        s.allCoCreationContests = result;
      });
    }
  });
  return successResult();
});

export const logout = () => {
  // console.log('LOGGING OUT USER');
  DslStore.update((s) => {
    s.currentUser = undefined;
  });
  axiosHelper.logout();
};

export const setDesignCommentsVisibility = (number: number) => {
  if (number === 0 || number === 1) {
    DslStore.update((s) => {
      s.designCommentsVisibility = number;
    });
  }
};

export const setCurrentDesign = (design: DesignDetails) => {
  // console.log(design);
  DslStore.update((s) => {
    s.currentDesign = design;
  });
};

export const setContestGallery = (designs: GalleryDesign[]) => {
  // console.log(designs);
  DslStore.update((s) => {
    s.contestGalleryDesigns = designs;
  });
};

export const setContestGalleryCopy = (designs: GalleryDesign[]) => {
  // console.log(designs);
  DslStore.update((s) => {
    s.contestGalleryDesignsCopy = designs;
  });
};

export const setCoCreationContestGallery = (designs: GalleryDesign[]) => {
  // console.log(designs);
  DslStore.update((s) => {
    s.cocreationContestGallery = designs;
  });
};

export const setCoCreationContestAward = (awards: Award[]) => {
  // console.log(awards);
  DslStore.update((s) => {
    s.cocreationContestAward = awards;
  });
};

export const setSearchResultContest = (contests: Contest[]) => {
  const userPerPage = 20;
  DslStore.update((s) => {
    s.searchResultContest = contests;
    s.contestSearchPageNumberLimit = Math.ceil(contests.length / userPerPage);
  });
};

export const setSearchResultContestFiltered = (contests: Contest[]) => {
  const userPerPage = 20;
  DslStore.update((s) => {
    s.searchResultContestFiltered = contests;
  });
};

export const setSearchResultContestCopy = (contests: Contest[]) => {
  DslStore.update((s) => {
    s.searchResultContestCopy = contests;
  });
};

export const setSearchResultUser = (users: User[]) => {
  const userPerPage = 20;
  DslStore.update((s) => {
    s.searchResultUser = users;
    s.userSearchPageNumberLimit = Math.ceil(users.length / userPerPage);
  });
};
export const setSearchResultUserCopy = (users: User[]) => {
  DslStore.update((s) => {
    s.searchResultUserCopy = users;
  });
};

export const setSearchResultDesign = (designs: GalleryDesign[]) => {
  const userPerPage = 20;
  DslStore.update((s) => {
    s.searchResultDesign = designs;
    s.designSearchPageNumberLimit = Math.ceil(designs.length / userPerPage);
  });
};

export const setSearchResultDesignCopy = (designs: GalleryDesign[]) => {
  DslStore.update((s) => {
    s.searchResultDesignCopy = designs;
  });
};

export const setContestSearchPageNumber = async (number: number) => {
  DslStore.update((s) => {
    s.contestSearchPageNumber = number;
  });
};

export const setUserSearchPageNumber = async (number: number) => {
  DslStore.update((s) => {
    s.userSearchPageNumber = number;
  });
};

export const setDesignSearchPageNumber = async (number: number) => {
  DslStore.update((s) => {
    s.designSearchPageNumber = number;
  });
};

export const setContestSearchParams = async (params: ContestSearchParams) => {
  DslStore.update((s) => {
    s.contestSearchParams = params;
  });
};
export const setContestSearchParamsFilterBy = async (filter: FilterBy) => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, filterBy: filter };
    s.contestSearchParams = newParams;
  });
};
export const setContestSearchParamsCategory = async (category?: string) => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, category: category };
    s.contestSearchParams = newParams;
  });
};
export const setContestSearchParamsSearch = async (sortBy: SortBy) => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, sortBy: sortBy };
    s.contestSearchParams = newParams;
  });
};
export const setContestSearchParamsOrder = async (orderBy: string) => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, orderBy: orderBy };
    s.contestSearchParams = newParams;
  });
};
export const invertContestSearchParamsOrder = async () => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, orderBy: params.orderBy === 'asc' ? 'desc' : 'asc' };
    s.contestSearchParams = newParams;
  });
};
export const setCurrentPage = async (page: number) => {
  DslStore.update((s) => {
    const params = s.contestSearchParams;
    const newParams = { ...params, currentPage: page };
    s.contestSearchParams = newParams;
  });
};
export const setNumberOfPages = async (pages: number) => {
  DslStore.update((s) => {
    if (pages === 0) {
      return;
    }
    const params = s.contestSearchParams;
    const prev = params.numberOfPages;
    if (prev === pages) {
      return;
    }
    const newParams = { ...params, numberOfPages: pages, currentPage: 1 };
    s.contestSearchParams = newParams;
  });
};
export const resetContestSearchParams = async () => {
  DslStore.update((s) => {
    const oldPages = s.contestSearchParams.numberOfPages;
    s.contestSearchParams = {
      category: undefined,
      currentPage: 1,
      numberOfPages: oldPages,
      sortBy: 'date',
      orderBy: 'desc',
      filterBy: 'all',
    };
  });
};

export const loadContestsByName = async (contests: ContestMini[]) => {
  DslStore.update((s) => {
    s.contestsByName = contests.map((contest) => {
      return { name: contest.name, id: contest.id };
    });
  });
};
