import React, { useEffect, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import { ProfileHeader } from '../../components/Profile/ProfileHeader/ProfileHeader';
import ContestStats from '../../components/Community/ContestStats/ContestStats';
import UserPic from '../../components/Community/UserPic/UserPic';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faCircle, faMapMarkerAlt, faFlask } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { clientNavPages, designerNavPages } from '../../mockdata/navPages';
import PageNavigation, { PageNavigationProfile } from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './Profile.module.scss';
import { useStoreState } from 'pullstate';
import DslStore, { setContestPortfolioPageNumber, setLayoutContestPortfolio } from '../../store/DslStore';
import {
  getCommunityUser,
  getContestPortfolioPageNumber,
  getCurrentContest,
  getFooterMenu,
  getHeaderMenu,
  getLayoutContestPortfolio,
  getPortfolioDesigns,
} from '../../store/Selectors';
import { fullPathLogo, locationString, locationStringForUser, registrationDateString } from '../../helpers/utils';
import {
  ContestPortfolioNavigation,
  PaginationSwitch,
} from '../../components/Profile/PortfolioNavigation/PortfolioNavigation';

import { UserDesigns } from '../../global';
import GalleryRow from '../../components/Contest/GalleryRow/GalleryRow';

import ContestPortfolioCard from '../../components/Profile/ContestPortfolioCard/ContestPortfolioCard';

// const mockGalleryImages = [
//   'https://source.unsplash.com/_HqHX3LBN18/800x680',
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/RFP4D5hGTB0/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

// const mockGalleryImagesHalf = [
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

const ContestPortfolio: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const layoutPortfolio = useStoreState(DslStore, getLayoutContestPortfolio);
  const pageNumber = useStoreState(DslStore, getContestPortfolioPageNumber);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const contest = useStoreState(DslStore, getCurrentContest);
  const portfolioDesign = useStoreState(DslStore, getPortfolioDesigns);

  const [visibleDesign, setVisibleDesign] = useState<UserDesigns[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: UserDesigns[] = [];
    let chunk: UserDesigns[] = portfolioDesign!.slice(startchunk, startchunk + chunkSize);

    // console.log(communityUser?.id);
    // console.log(portfolioDesigns);

    for (let i = 0; i < portfolioDesign!.length; i += chunkSize) {
      chunk = portfolioDesign!.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    // tempArray = tempArray.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    setVisibleDesign(tempArray);
  };

  useEffect(() => {
    arraysfill();

    // console.log(portfolioDesigns);
  }, [portfolioDesign]);

  useEffect(() => {
    // console.log(pageNumber);
    arraysfill();
    // console.log(visibleDesign);
    // fillContests(pageNumber -1);
  }, [pageNumber]);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Community/${communityUser?.username}`}
        // pageTitle={`${communityUser?.userType === 'D' ? 'Portfolio' : 'Contests'}`}
        pageTitle='Contests'
      />
      <PageNavigationProfile
        bgColor="#E3E3E3"
        links={communityUser?.userType === 'C' ? clientNavPages : designerNavPages}
      />
      <main className={`${styles.profileWrapper} container px-4 px-sm-0 mb-5 overflow-hidden`}>
        <div className="row px-2">
          <div className="col-12 ">
            <ProfileHeader bgImage={fullPathLogo(communityUser?.bannerAttachment?.s3Path)} />
          </div>
        </div>
        <div className="row px-4 px-sm-3">
          <div className="col-12 col-md-3 text-center position-relative ">
            <div className={`${styles.profilePic} ${styles.picUp} px-2`}>
              <UserPic image={communityUser?.logoAttachment?.s3Path} />
            </div>
            {/* <div className={`${styles.badges} d-flex justify-content-end`}>
              <div className={`${styles.badge} bg-red color-light`}>
                <FontAwesomeIcon icon={faFlask as IconProp} />
              </div>
              <div className={`${styles.badge} bg-green color-light`}>
                <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
              </div>
            </div> */}
          </div>
          <div className="col-12 col-md-2 pt-5">
            <div className="info-main mb-7">
              <h1 className="sub-title color-blue">{communityUser?.username}</h1>
              <div className="text color-dark">{communityUser?.job?.name}</div>
            </div>

            <div className="info">
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">{locationStringForUser(communityUser)}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">since {registrationDateString(communityUser!.registrationDate)}</div>
              </div>
            </div>
            <div className="badges">
              <div className="d-flex justify-content-start">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCircle as IconProp} className=" text color-green me-3" />
                </div>
                <div className="text color-green">Dream Team user</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 mt-5">
            <ContestStats
              entries={communityUser!.badgeDesignsOut}
              inspirations={communityUser!.badgeInspirationLikeIn}
              awards={communityUser!.badgeAmountIn}
              contests={communityUser!.contestsCount}
              reputation={communityUser!.reputationCount}
              favorites={communityUser!.badgeFavoritesCount}
              votes={communityUser!.badgeVotesIn}
              comments={communityUser!.badgeCommentsIn}
            />
          </div>
        </div>
        {/* Projecst ============================================================================= */}
        <ContestPortfolioNavigation onChangeLayout={setLayoutContestPortfolio} />
        <div className="row px-2">
          {layoutPortfolio === 1
            ? visibleDesign.map(
                (aDesign: UserDesigns, index: number) => (
                  console.log(aDesign),
                  (<ContestPortfolioCard key={index} design={aDesign} global={false}></ContestPortfolioCard>)
                )
              )
            : ''}
        </div>
        <div className="row mt-3 px-4">
          <div className="px-2">
            {layoutPortfolio === 2
              ? visibleDesign.map((aDesign: UserDesigns, index: number) => (
                  <GalleryRow
                    username={communityUser?.username}
                    userId={communityUser?.id}
                    contestTitle={contest?.name}
                    votes={aDesign.votesCount}
                    name={contest?.userName}
                    creationDate={aDesign.uploadDate}
                    galleryImages={[aDesign.image1Attachment.s3Path]}
                    idContest={contest?.id}
                    image={aDesign.image1Attachment.s3Path}
                    designName={aDesign.name}
                    idDesign={aDesign.id}
                    private={aDesign.contest.isPrivate}
                    hidden={aDesign.isHidden}
                    idClient={aDesign.contest.client.id}
                    endUploadDate={aDesign.contest.endUploadDate}
                    startDate={aDesign.contest.startUploadDate}
                  ></GalleryRow>
                ))
              : ''}
          </div>
        </div>

        <div className="row px-2">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end">
            <PaginationSwitch onChangePage={setContestPortfolioPageNumber} />
          </div>
        </div>

      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ContestPortfolio;
