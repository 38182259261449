import React from 'react';
import styles from './SquaredImage.module.scss';
interface ISquaredImage {
  image: string;
  circle?: boolean;
  altText?: string;
}

const SquaredImage: React.FC<ISquaredImage> = (props) => {
  return (
    <div className={`${styles.squaredImageWrapper} ${props.circle ? styles.circle : ''}`}>
      <div className={`${styles.imageInner} `}>
        <img src={props.image} alt={props.altText} />
      </div>
    </div>
  );
};

export default SquaredImage;
