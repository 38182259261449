import React, { FC, useEffect, useState } from 'react';
import { Skill } from '../../../global';
import useSWR from 'swr';
import axiosHelper from '../../../helpers/axiosHelper';
import styles from '../YourProfile/YourProfile.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SkillsEditorProps {
  skills: Skill[];
  onChange: (skills: Skill[]) => void;
}

const SkillsEditor: FC<SkillsEditorProps> = ({ skills, onChange }) => {
  const { data, error, isLoading } = useSWR('skills', axiosHelper.skillsFetcher);
  const [allTags, setAllTags] = React.useState<Skill[]>([]);
  const [suggestionArray, setSuggestionArray] = React.useState<Skill[]>([]);

  const [skillString, setSkillString] = useState('');

  useEffect(() => {
    if (data) {
      setAllTags(data);
    }
  }, [data]);

  const addSkill = (skill: Skill) => {
    if (!skills.find((item) => item.name === skill.name)) {
      onChange([...skills, skill]);
    }
    setSkillString('');
    setSuggestionArray([]);
  };
  const removeSkill = (item: Skill) => {
    onChange(skills.filter((tag) => tag.name !== item.name));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleInputChange');
    const inputValue = e.target.value.replace(/[^\w\s]/gi, ''); // Remove punctuation symbols
    setSkillString(inputValue);
    const filteredTags = allTags.filter((tag) => tag.name.toLowerCase().startsWith(inputValue.toLowerCase()));
    setSuggestionArray(filteredTags);
  };

  function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
    setTimeout(() => {
      setSuggestionArray([]);
      setSkillString('');
    }, 300);
  }
  function handleFocus(event: React.FocusEvent<HTMLInputElement>): void {
    if (skillString.length > 0) {
      const filteredTags = allTags.filter((tag) => tag.name.toLowerCase().startsWith(skillString.toLowerCase()));
      setSuggestionArray(filteredTags);
    } else {
      setSuggestionArray(allTags);
    }
  }

  return (
    <>
      <div className="row mb-5">
        <div className="col-12 col-md-2 mb-3 text fw-bold">Skills</div>
        <div className="col-12 col-md-5">
          <input type="text" id="social" value={skillString} placeholder="...start typing" onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
          {suggestionArray.length > 0 && (
            <div className={`${styles.suggestionWrapper}`}>
              {suggestionArray.map((item: Skill, index: number) => (
                <div className={`text ${styles.suggestionRow}`} key={index} onClick={() => addSkill(item)}>
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {skills.length > 0 && (
        <div className="row mb-5">
          <div className="col-12 col-md-2 mb-3 text"></div>

          <div className="col-12 col-md-10 ">
            {skills.map(
              (item: Skill, index: number) =>
                item && (
                  <button
                    type="button" // Prevent form submission by setting button type
                    className="btn-tag-editable mb-4 "
                    key={index}
                    onClick={() => removeSkill(item)}
                  >
                    <span>
                      {item.name}
                      <FontAwesomeIcon icon={faXmark as IconProp} className="icon-tag" />{' '}
                    </span>
                  </button>
                )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SkillsEditor;
