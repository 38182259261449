import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { Contest, User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';

interface AccessCodeFormProps {
  contest: Contest;
  loggedUser: User | null;
  accessCodeWasValid: () => void;
  loginCallback: () => void;
}

const AccessCodeForm: FC<AccessCodeFormProps> = ({ contest, loggedUser, accessCodeWasValid, loginCallback }) => {
  const [accessCode, setAccessCode] = useState('');

  const tryAccessCode = async () => {
    if (loggedUser) {
      if (contest.id) {
        if (contest.accessCode) {
          await axiosHelper
            .verifyAccessCode(contest.id, accessCode)
            .then((response) => {
              if (response.status === 200) {
                accessCodeWasValid();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      loginCallback();
    }
  };

  return (
    <>
      <div className="row justify-content-start "></div>
      <div className="row justify-content-start ">
        <div className="col-3 col-md-3  ">
          <span className="text-16 fw-bold">Access code</span>
        </div>
        <div className="col-10 col-md-9 mb-2 ">
          <span className="text d-flex flex-row align-items-center">
            <div className="bg-warning d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', height: '20px', width: '20px' }}>
              <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
            </div>
            <span className="ms-3">
              {loggedUser ? (
                <span>You must provide a valid Access code in order to read the full brief and join the contest.</span>
              ) : (
                <span>
                  You must <a href="/login">login</a> (or <a href="/signup">signup</a>) and provide a valid Access code in order to read the full brief and join the contest.
                </span>
              )}
            </span>
          </span>
        </div>
        <div className="col-3 col-md-3"></div>
        <div className="col-7 col-md-7  ">
          <input type="text" onChange={(e) => setAccessCode(e.target.value)} />
        </div>
        <div className="col-2 col-md-2  ">
          <button className="btn-success  full mb-4 rounded " onClick={() => tryAccessCode()}>
            save
          </button>
        </div>
      </div>
    </>
  );
};

export default AccessCodeForm;
