import React, { useEffect, useState } from 'react';

import { useStoreState } from 'pullstate';

import profilePlaceholder from '../../assets/Profile.png';

import Skeleton from '@mui/material/Skeleton';
import DslStore, { setLayoutCommunity, setSearchResultUser, setUserSearchPageNumber } from '../../store/DslStore';
import {
  getCurrentUser,
  getLayoutCommunity,
  getSearchResultUser,
  getSearchResultUserCopy,
  getUserSearchPageNumber,
} from '../../store/Selectors';
import DesignerCard from '../../components/Community/DesignerCard/DesignerCard';
import DesignerRow from '../../components/Community/DesignerRow/DesignerRow';
import CommunityNavigation, {
  CommunityPaginationSwitch,
} from '../../components/Community/ProjectsNavigation/CommunityNavigation';
import { User } from '../../global';
import axiosHelper from '../../helpers/axiosHelper';
import {
  numberVotes,
  userTypeString,
  jobString,
  locationString,
  registrationDateString,
  fullPathLogoCommunity,
} from '../../helpers/utils';
import UsersNavigation, { UserSearchPaginationSwitch } from '../../components/SearchNavigation/UsersNavigation';

const mockGalleryImages = [
  'https://source.unsplash.com/_HqHX3LBN18/800x680',
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/RFP4D5hGTB0/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const UserSearch: React.FC = () => {
  const allUserSearch = useStoreState(DslStore, getSearchResultUser);
  //   const allUsers = useStoreState(DslStore, getUsers);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const layoutCommunity = useStoreState(DslStore, getLayoutCommunity);
  const pageNumber = useStoreState(DslStore, getUserSearchPageNumber);
  const searchResultUserCopy = useStoreState(DslStore, getSearchResultUserCopy);
  const userPerPage = 20;
  const [pageNumberLimit, setPageNumberLimit] = useState(Math.ceil(allUserSearch.length / userPerPage));

  const [visibleUsers, setVisibleUsers] = useState<User[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: User[] = [];
    const chunk: User[] = allUserSearch.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allUserSearch.length; i += chunkSize) {
      const chunk: User[] = allUserSearch.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log('array1:  ');
    // console.log(tempArray);
    setVisibleUsers(tempArray);
  };

  useEffect(() => {
    console.log('sono dentro community-search');
    console.log(searchResultUserCopy.length);
    if (searchResultUserCopy.length > 0) {
      setSearchResultUser(searchResultUserCopy);
    }
    arraysfill();
    setUserSearchPageNumber(1);
  }, []);

  useEffect(() => {
    setVisibleUsers(allUserSearch);
    setPageNumberLimit(Math.ceil(allUserSearch.length / userPerPage));
    arraysfill();
  }, [allUserSearch]);

  useEffect(() => {
    setVisibleUsers(allUserSearch);
    arraysfill();
  }, [pageNumber]);

  const isLiked = (idUser?: number) => {
    if (idUser) {
      let state: boolean = false;
      loggedUser &&
        loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.findIndex((item) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  return (
    <>
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className="container mb-5  px-4 px-sm-0">
        <div className="row">
          <div className="col-12 ">
            <UsersNavigation onChangeLayout={setLayoutCommunity} />
          </div>
        </div>
        <div className="row  px-0  ">
          {layoutCommunity === 1 ? (
            visibleUsers.length > 0 ? (
              visibleUsers?.map(
                (aUser: User, index: number) => (
                  console.log(aUser),
                  (
                    <div className=" px-sm-3 px-md-0 my-3 col-12 col-md-6 col-lg-4 col-xl-3">
                      <DesignerCard
                        key={index}
                        user={aUser}  
                      ></DesignerCard>
                    </div>
                  )
                )
              )
            ) : (
              <>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
              </>
              // <NoContent />
            )
          ) : (
            ''
          )}
        </div>

        <div className="row px-lg-1 ">
          {layoutCommunity === 2 ? (
            visibleUsers.length > 0 ? (
              visibleUsers?.map((aUser: User, index: number) => (
                // console.log(aUser),
                <div className="col-12 px-lg-3  d-flex flex-row align-items-center justify-content-center">
                  <DesignerRow
                    key={index}
                    user={aUser}
                    galleryImages={mockGalleryImages}
                  ></DesignerRow>
                </div>
              ))
            ) : (
              <>
                <div className=" col-12 mb-5 ">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
              </>
              // <NoContent />
            )
          ) : (
            ''
          )}
        </div>
        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end">
            <UserSearchPaginationSwitch onChangePage={setUserSearchPageNumber} />
          </div>
        </div>
      </main>
    </>
  );
};

export default UserSearch;
