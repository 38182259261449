import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './FileUpload.module.scss';
import axios from 'axios';
import axiosHelper from '../../../../helpers/axiosHelper';

interface IFileUpload {
  uploadFinished: (uuid: string, previewUrl: string) => void;
}

const HeaderUpload: React.FC<IFileUpload> = (props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState<number>(0);

  const handleDrop = async (files: File[]) => {
    // console.log(files);
    let file = files[0];
    setIsUploading(true);
    setProgress(0);
    setTimeout(() => {
      getPresignedUrl(file);
    }, 100);
  };
  const getPresignedUrl = async (file: File) => {
    console.log('getPresignedUrl');
    await axiosHelper.getPresignedUrl(1, file.name, file.size, file.type)?.then((response) => {
      console.log(response.data);
      console.log('uuid: ' + response.data.id);
      setIsUploading(true);

      sendImage(response.data.payload, response.data.id, file).then((risposta) => {
        console.log('risposta');
        console.log(risposta);
        props.uploadFinished(response.data.id, URL.createObjectURL(file));
        setProgress(100);
        return;
      });
    });
  };

  async function sendImage(link: any, id: string, file: any) {
    console.log('sendImage');
    if (file) {
      await axios
        .put(link, file, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
            }
          },
        })
        .then((res) => {
          console.log(res);
          if (res) {
            console.log(file);
          }
        })
        .catch((error) => {
          console.log(error);
          // setIsUploading(false);
        });
    }
  }

  const DropZoneView = () => {
    return (
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className={`row ${styles.dropArea}`}>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center " {...getRootProps()}>
              <input {...getInputProps()} />
              <button className={`${styles.addButton} bg-blue`}>
                <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
              </button>
              <div>
                .jpg, .png <br /> 1170x270 px
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const UploadingView = () => {
    return (
      <div className={`row ${styles.progressArea}`}>
        <div className="col-12 mb-5"></div>
        <div className="col-12">
          <div className={`${styles.progressBarWrapper}`}>
            <div className={`${styles.progressBar}`} style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.uploadContainer} ${isUploading && styles.inProgress}`}>{isUploading ? <UploadingView /> : <DropZoneView />}</div>
    </>
  );
};

export default HeaderUpload;
