import React, { useEffect, useState } from 'react';
import { INavLink } from '../../../global';
import { designerNavPages } from '../../../mockdata/navPages';
import styles from './ProfileNavigation.module.scss';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { Link } from 'react-router-dom';
interface Props {
  links: INavLink[];
  currentPage: INavLink;
  username: String;
}

const ProfileNavigationWLF: React.FC<Props> = ({ links, currentPage, username }) => {
  // const [links, setLinks] = useState<INavLink[]>(designerNavPages);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log(links);
    console.log(currentPage);
    console.log(username);
  }, [links, currentPage, username]);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const isActive = (aPage: INavLink) => {
    return aPage === currentPage;
  };
  window.addEventListener('scroll', callbackFunction);

  return (
    <div className={`${styles.navigationWrapperProfile}  container-fluid ${!visible ? '' : styles.fixed}`} style={{ backgroundColor: '#E3E3E3' }}>
      <div className="container-md ">
        <div className="row px-md-2 ps-md-3">
          <div className={`col-12 d-flex flex-nowrap px-lg-0 ${styles.tabsWrapperProfile}`}>
            {links.map((navLink, index) => (
              <Link
                key={index}
                className={`text color-dark flex-grow-1 flex-shrink-0  text-center ${styles.pageTabProfile} ${isActive(navLink) && styles.activeTabProfile}`}
                to={`/${ROUTEPATHS.COMMUNITY}/${username}${navLink.path}`}
              >
                <span className="text-16">{navLink.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigationWLF;
