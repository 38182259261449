import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { ContestTag, Skill, User } from '../../../../global';
import axiosHelper from '../../../../helpers/axiosHelper';
import PopUpLogin from '../../../../components/PopUp/PopUpLogin/PopUpLogin';
import PopUpSendMessage from '../../../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

interface Props {
  user: User;
}
const ProfileColumn: React.FC<Props> = ({ user }) => {
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const { loggedUser, isUserLiked, toggleUserLiked, isUserBlacklisted, toggleUserBlacklisted } = useAuth();
  const [popupLogin, setPopupLogin] = useState(false);
  const [popupMessage, setPopupMessage] = useState(false);
  const banUser = async () => {
    if (loggedUser?.userType === 'G' && user?.id) {
      await axiosHelper
        .banUser(user.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (!loggedUser) {
      setButtonsVisible(true);
    } else {
      if (loggedUser.id !== user.id) {
        setButtonsVisible(true);
      } else {
        setButtonsVisible(false);
      }
    }
  }, [loggedUser, user]);

  return (
    <div className="col-12  col-md-3 ">
      {buttonsVisible && (
        <div className="buttons ">
          <button className={`${isUserLiked(user) ? 'btn-danger' : 'btn-blue'} rounded full  mb-3`} onClick={() => (loggedUser ? toggleUserLiked(user) : setPopupLogin(true))}>
            {isUserLiked(user) ? 'Remove from favorites' : 'Add to favorites'}
          </button>
          <button className="btn-blue rounded full mb-3" onClick={() => (loggedUser ? setPopupMessage(true): setPopupLogin(true))}>Send Message
          </button>
          {loggedUser && user?.userType === 'D' && !user?.hasPrivateMessagesNotification && <button className="btn-blue rounded full mb-3">Do not disturb</button>}
          <button
            className={`${isUserBlacklisted(user) ? `btn-warning` : `btn-red`} rounded big full mb-3`}
            onClick={() => (loggedUser ? toggleUserBlacklisted(user) : setPopupLogin(true))}
          >
            {isUserBlacklisted(user) ? 'Unblock user' : 'Block user'}
          </button>

          {loggedUser?.userType === 'G' && (
            <button className="btn-red rounded big full mb-3" onClick={() => banUser()}>
              Ban user
            </button>
          )}
          {popupMessage && user && (
            <PopUpSendMessage
              popup={popupMessage}
              close={() => setPopupMessage(false)}
              setMessageNumber={(message) => (console.log(message), setPopupMessage(false))}
              recipient={user.username}
            />
          )}

          {popupLogin && <PopUpLogin popup={popupLogin} close={() => setPopupLogin(false)} login={(e: boolean) => setPopupLogin(e)} />}
        </div>
      )}
      <div className={`tags ${loggedUser?.id !== user?.id && 'mt-5'}  d-flex flex-wrap`}>
        {user.skills && user.skills.length > 0 ? (
          <>
            {user.skills &&
              user.skills.map((item: Skill, index: number) => (
                <button key={index} className="btn-tag mb-4 px-0 me-5 ">
                  <span>{item.name}</span>
                </button>
              ))}
          </>
        ) : (
          <>
            {user.tags &&
              user.tags.map((item: ContestTag, index: number) => (
                <button key={index} className="btn-tag mb-4 px-0 me-5 ">
                  <span>{item.name.replace(',', '')}</span>
                </button>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileColumn;
