import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import AxiosHelper from '../helpers/axiosHelper';
import { Contest, Design, GalleryDesign, User } from '../global';
import axiosHelper from '../helpers/axiosHelper';

interface AuthContextType {
  loggedUser: User | null;
  hideHeader: boolean;
  setHideHeader: (hide: boolean) => void;
  login: (email: string, password: string, rememberMe: boolean) => Promise<User>;
  fbLogin: (email: string, token: string, uid: string) => Promise<User>;
  ggLogin: (email: string, token: string, uid: string) => Promise<User>;
  twLogin: (token: string, uid: string) => Promise<User>;
  lkLogin: (token: string, uid: string) => Promise<User>;
  tokLogin: (token: string) => Promise<User>;
  logout: () => void;
  setLoggedUser: (user: User | null) => void;
  isContestLiked: (contest: Contest) => boolean;
  toggleContestLiked: (contest: Contest) => void;
  isDesignLiked: (design: Design | GalleryDesign) => boolean;
  toggleDesignLiked: (design: Design | GalleryDesign) => void;
  isUserLiked: (user: User) => boolean;
  toggleUserLiked: (user: User) => void;
  isUserBlacklisted: (user: User) => boolean;
  toggleUserBlacklisted: (user: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  useEffect(() => {
    AxiosHelper.checkSession()
      .then((result) => {
        setLoggedUser(result);
      })
      .catch(() => {
        setLoggedUser(null);
      });
  }, []);

  const login = async (email: string, password: string, rememberMe: boolean): Promise<User> => {
    console.log('Logging in', email);
    try {
      const data = await AxiosHelper.login(email, password, rememberMe);

      if (data) {
        setLoggedUser(data);
        return data;
      } else {
        setLoggedUser(null);
        throw new Error('Login unsuccessful');
      }
    } catch (e) {
      throw new Error('Did crash');
    }
  };

  const fbLogin = async (email: string, token: string, uid: string): Promise<User> => {
    try {
      const data = await AxiosHelper.facebookTokenLogin({ email: email, token: token, uid: uid });
      if (data) {
        setLoggedUser(data);
        return data;
      }
      setLoggedUser(null);
      throw new Error('DID NOT SUCCEED');
    } catch (e) {
      throw new Error('Did crash');
    }
  };
  const ggLogin = async (email: string, token: string, uid: string): Promise<User> => {
    try {
      const data = await AxiosHelper.googleTokenLogin({ email: email, token: token, uid: uid });
      if (data) {
        setLoggedUser(data);
        return data;
      }
      setLoggedUser(null);
      throw new Error('DID NOT SUCCEED');
    } catch (e) {
      throw new Error('Did crash');
    }
  };
  const twLogin = async (token: string, uid: string): Promise<User> => {
    try {
      const data = await AxiosHelper.twitterTokenLogin({ token: token, uid: uid });
      if (data) {
        setLoggedUser(data);
        return data;
      }
      setLoggedUser(null);
      throw new Error('DID NOT SUCCEED');
    } catch (e) {
      throw new Error('Did crash');
    }
  };
  const lkLogin = async (token: string, uid: string): Promise<User> => {
    try {
      const data = await AxiosHelper.linkedinTokenLogin({ token: token, uid: uid });
      if (data) {
        setLoggedUser(data);
        return data;
      }
      setLoggedUser(null);
      throw new Error('DID NOT SUCCEED');
    } catch (e) {
      throw new Error('Did crash');
    }
  };

  const tokLogin = async (token: string): Promise<User> => {
    const data = await AxiosHelper.tokLogin(token);
    if (data) {
      setLoggedUser(data);
      return data;
    }
    setLoggedUser(null);
    throw new Error('DID NOT SUCCEED');
  };

  const logout = () => {
    setLoggedUser(null);
    AxiosHelper.logout();
  };

  const isContestLiked = (contest: Contest) => {
    if (!loggedUser) return false;
    return loggedUser.contestsILike.some((item) => item.id === contest.id);
  };

  const toggleContestLiked = (contest: Contest) => {
    if (!loggedUser) return;
    if (isContestLiked(contest)) {
      axiosHelper.unlikeContest(contest.id).then((response) => {
        setLoggedUser({ ...loggedUser, contestsILike: response.data.contestsILike });
      });
    } else {
      axiosHelper.likeContest(contest.id).then((response) => {
        setLoggedUser({ ...loggedUser, contestsILike: response.data.contestsILike });
      });
    }
  };

  const isDesignLiked = (design: Design | GalleryDesign) => {
    if (!loggedUser) return false;
    return loggedUser.designsILike.some((item) => item.id === design.id);
  };

  const toggleDesignLiked = (design: Design | GalleryDesign) => {
    if (!loggedUser) return;
    if (isDesignLiked(design)) {
      axiosHelper.unlikeDesign(design.id).then((response) => {
        setLoggedUser({ ...loggedUser, designsILike: response.data.designsILike });
      });
    } else {
      axiosHelper.likeDesign(design.id).then((response) => {
        setLoggedUser({ ...loggedUser, designsILike: response.data.designsILike });
      });
    }
  };

  const isUserLiked = (user: User) => {
    if (!loggedUser) return false;
    return loggedUser.usersILike.some((item) => item.id === user.id);
  };

  const toggleUserLiked = (user: User) => {
    if (!loggedUser) return;
    if (isUserLiked(user)) {
      axiosHelper.unlikeUser(user.id).then((response) => {
        setLoggedUser({ ...loggedUser, usersILike: response.data.usersILike });
      });
    } else {
      axiosHelper.likeUser(user.id).then((response) => {
        setLoggedUser({ ...loggedUser, usersILike: response.data.usersILike });
      });
    }
  };

  const isUserBlacklisted = (user: User) => {
    if (!loggedUser) return false;
    return loggedUser.blacklistedUsers.some((item) => item.id === user.id);
  };

  const toggleUserBlacklisted = (user: User) => {
    if (!loggedUser) return;
    if (isUserBlacklisted(user)) {
      axiosHelper.removeUserFromBlackList(user.id).then((response) => {
        setLoggedUser({ ...loggedUser, blacklistedUsers: response.data.blacklistedUsers });
      });
    } else {
      axiosHelper.addUserToBlackList(user.id).then((response) => {
        setLoggedUser({ ...loggedUser, usersILike: response.data.blacklistedUsers });
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loggedUser,
        hideHeader,
        setHideHeader,
        login,
        fbLogin,
        ggLogin,
        twLogin,
        lkLogin,
        tokLogin,
        logout,
        setLoggedUser,
        isContestLiked,
        toggleContestLiked,
        isDesignLiked,
        toggleDesignLiked,
        isUserLiked,
        toggleUserLiked,
        isUserBlacklisted,
        toggleUserBlacklisted,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
