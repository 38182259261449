import React, { useEffect, useState } from 'react';
import styles from './ProfilePic.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../../Community/UserPic/UserPic';
import PicUpload from './FileUpload/PicUpload';
import axiosHelper from '../../../helpers/axiosHelper';
import { User } from '../../../global';
import { mutate } from 'swr';

interface ProfilePicWLFProps {
  user: User;
  editable?: boolean;
  children?: React.ReactNode;
}

const ProfilePicWLF: React.FC<ProfilePicWLFProps> = ({ user, editable, children }) => {
  const [imageURL, setImageURL] = useState<string>('');

  useEffect(() => {
    return () => {
      if (imageURL && imageURL.startsWith('blob:')) {
        console.log('cleanup was needed');
        URL.revokeObjectURL(imageURL);
      }
    };
  }, [imageURL]);

  useEffect(() => {
    if (user.logoAttachment) {
      setImageURL(user.logoAttachment?.s3Path);
    }
  }, [user]);

  const removeClicked = (e: any) => {
    e.preventDefault();
    console.log('remove clicked');
    axiosHelper.removeUserLogo(user.id).then((response) => {
      console.log(response);
      mutate('user|' + user.username, { ...user, logoAttachment: undefined }, { revalidate: false }); // Update localStorage cache
      setImageURL('');
    });
  };
  const handleImageAdd = (e: any) => {
    // if (props.onImageAdd) {
    //   console.log('aggiunta immagine');
    //   console.log(e);
    // } else return false;
  };

  const handleUploadFinished = (uuid: string, url: string) => {
    console.log('handleUploadFinished');
    console.log(uuid);
    console.log(url);
    setImageURL(url);
    axiosHelper.updateUserImages(uuid, user.id).then((response) => {
      if (response) {
        mutate('user|' + user.username, response, { revalidate: false }); // Update localStorage cache
      }
    });
  };
  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={handleImageAdd}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light " />
      </button>
    );
  };

  const AddArea = () => {
    return (
      <div className={`${styles.addArea}`}>
        <PicUpload onImageAdd={handleImageAdd} uploadFinished={handleUploadFinished} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-danger`} onClick={removeClicked}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div className={`${styles.profilePicWrapper}  position-relative `}>
      <div className="position-relative ">
        {/* <UserPic image={props.image} /> */}

        <UserPic image={imageURL} />
        {/* area per grafica per aggiungere immagine */}
        {!imageURL && editable && <AddArea />}
      </div>
      {imageURL && editable && <RemoveButton />}
      {imageURL ? (
        <div className={`${styles.badges}`}>
          {children ? <div className="d-flex ">{children}</div> : ''}
          {!imageURL && editable && <AddButton />}
        </div>
      ) : (
        <div className={`${styles.badgesTransformed} d-flex justify-content-end`}>
          {children && editable ? <div className="d-flex ">{children}</div> : <div className="d-flex pt-7"></div>}
          {/* {!props.image && props.editable && <AddButton />} */}
        </div>
      )}
    </div>
  );
};

export default ProfilePicWLF;
