import React, { useEffect, useState } from 'react';
import { Country, Job } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import styles from './DropDown.module.scss';

interface Props {
  value?: Job;
  onChange: (job: Job) => void;
}

const JobSelect: React.FC<Props> = ({ value, onChange }) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    let result: Job[] = await axiosHelper.getJobs();
    if (result) {
      // console.log(result);
      setJobs(result);
    }
  };

  const isSelected = (option: Job) => {
    return value && value.id === option.id ? styles.selected : '';
  };

  const selectJob = (job: Job) => {
    onChange(job);
    setOpen(false);
  };

  return (
    <div className={`${styles.dropdown_wrapper_year}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={() => setOpen(!open)}>
          <span className={`${styles.dropdown__label} w-75`}>{value ? value.name : 'Select job...'}</span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {jobs.map((option, index) => (
            <div key={index} className={`${styles.dropdown__option} ${isSelected(option)}`} data-value={option.name} onClick={() => selectJob(option)}>
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  // return (
  //   <select value={value?.id || ''} onChange={handleCountryChange}>
  //     <option value="">Select a country</option>
  //     {countries.map((country) => (
  //       <option key={country.id} value={country.id}>
  //         {country.name}
  //       </option>
  //     ))}
  //   </select>
  // );
};

export default JobSelect;
