import { INavLink } from '../global';

export const topNavPages: INavLink[] = [
  {
    text: 'Home',
    path: '/',
    id: 0,
  },
  {
    text: 'Contest',
    path: '/contest',
    id: 1,
  },
  {
    text: 'Community',
    path: '/community',
    id: 2,
  },
  {
    text: 'Companies',
    path: '/companies',
    id: 3,
  },
  {
    text: 'Creatives',
    path: '/creatives',
    id: 4,
  },
];

export const searchResultNavPages: INavLink[] = [
  {
    text: 'Community',
    path: '/Search/Community',
    id: 0,
  },
  {
    text: 'Design',
    path: '/Search/Design',
    id: 1,
  },
  {
    text: 'Contest',
    path: '/Search/Contest',
    id: 2,
  },
];

export const briefNavPages: INavLink[] = [
  {
    text: 'Brief',
    path: '/brief',
    id: 0,
  },
  {
    text: 'Inspirations',
    path: '/inspirations',
    id: 1,
  },
  {
    text: 'Awards',
    path: '/awards',
    id: 2,
  },
  {
    text: 'Gallery',
    path: '/gallery',
    id: 3,
  },
  {
    text: 'Upload',
    path: '/upload-contest',
    id: 4,
  },
];

export const designerNavPages: INavLink[] = [
  {
    text: 'Profile',
    path: '/Profile',
    id: 0,
  },
  {
    text: 'Contest portfolio',
    path: '/portfolio',
    id: 1,
  },
  {
    text: 'Inspirations',
    path: '/designer-inspirations',
    id: 2,
  },
  {
    text: 'Favorites',
    path: '/user-favorites',
    id: 3,
  },
  {
    text: 'Badge',
    path: '/user-badge',
    id: 4,
  },
  {
    text: 'Awards',
    path: '/user-awards',
    id: 5,
  },
];
export const personalProfileNavPages: INavLink[] = [
  {
    text: 'Edit profile',
    path: '/profile-editable',
    id: 0,
  },
  // {
  //   text: 'Personal portfolio',
  //   path: '/',
  //   id: 1,
  // },
  {
    text: 'Contest portfolio',
    path: '/portfolio',
    id: 2,
  },
  {
    text: 'Inspirations',
    path: '/designer-inspirations',
    id: 3,
  },
  {
    text: 'Favorites',
    path: '/user-favorites',
    id: 4,
  },
  {
    text: 'Ambassador',
    path: '/ambassador',
    id: 5,
  },
  {
    text: 'Badge',
    path: '/user-badge',
    id: 6,
  },
  {
    text: 'Awards',
    path: '/user-awards',
    id: 7,
  },
];

export const personalClientNavPages: INavLink[] = [
  {
    text: 'Edit profile',
    path: '/profile-editable',
    id: 0,
  },
  {
    text: 'Contests',
    path: '/contests',
    id: 2,
  },
  {
    text: 'Inspirations',
    path: '/designer-inspirations',
    id: 3,
  },
  {
    text: 'Favorites',
    path: '/user-favorites',
    id: 4,
  },

  {
    text: 'Badge',
    path: '/user-badge',
    id: 6,
  },
];

export const clientNavPages: INavLink[] = [
  {
    text: 'Profile',
    path: '/Profile',
    id: 0,
  },
  {
    text: 'Contests',
    path: '/contests',
    id: 2,
  },
  {
    text: 'Favorites',
    path: '/user-favorites',
    id: 4,
  },

  {
    text: 'Badge',
    path: '/user-badge',
    id: 6,
  },
];
