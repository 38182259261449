import React, { useEffect, useState } from 'react';
import { SocialNetwork, SocialNetworkFinder } from '../../../global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axiosHelper from '../../../helpers/axiosHelper';
import styles from './YourProfessionalProfile.module.scss';
import SocialIcon from '../../SocialIcon/SocialIcon';

interface Props {
  socialNetworks: SocialNetwork[];
  onChange: (socialNetworks: SocialNetwork[]) => void;
}

const SocialEditor: React.FC<Props> = ({ socialNetworks , onChange }) => {
  const [socialString, setSocialString] = useState('');
  const [socialError, setSocialError] = useState('');
  const [finders, setFinders] = useState<SocialNetworkFinder[]>([]);

  const getFinders = async () => {
    let result: SocialNetworkFinder[] = await axiosHelper.getSocialNetworkFinder();
    if (result) {
      console.log(result);
      setFinders(result);
    }
  };

  useEffect(() => {
    getFinders();
  }, []);

  const removeSocial = ( index:number) => {
    console.log(index + ' REMOVE ' + index);
    onChange(socialNetworks.filter((_, i) => i !== index));
  };

  const addSocial = () => {
    setSocialError('');

    let socialURL = socialString.toLowerCase().trim();
    if (socialURL === '') {
      setSocialError('Please enter a social network URL');
      return;
    }
    if (!socialString.startsWith('https://')) {
      setSocialError('Please enter a valid URL');
      return;
    }
    var socialFinder = undefined;
    finders.forEach((finder) => {
      if (finder.isDefault) {
        socialFinder = finder;
      } else {
        if (socialURL.includes(finder.comparisonString)) {
          socialFinder = finder;
        }
      }
    });

    if (!socialFinder) {
      setSocialError('Something went wrong, please try again');
      return;
    }
    const newSocial: SocialNetwork = {
      socialNetworkFinder: socialFinder,
      type: 'SocialNetwork',
      url: socialURL,
    };
    onChange([...socialNetworks, newSocial]);
    setSocialString('');
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-12 col-md-2 mb-3 text fw-bold">Social Networks:</div>
        <div className="col-12 col-md-8">
          <input
            type="text"
            id="social"
            value={socialString}
            placeholder="Enter social network URL"
            onChange={(e) => {
              setSocialString(e.target.value);
              setSocialError('');
            }}
          />
        </div>
        <div className="col-12 col-md-2 ">
          <div className={`btn-blue full rounded d-flex flex-row align-items-center ${styles.button} `} onClick={addSocial}>
            New social
          </div>
        </div>
        {socialError && (
          <>
            <div className="col-12 col-md-2 mb-3 text fw-bold"></div>
            <div className="col-12 col-md-10">
              <span className="color-dark_red ps-3"> {socialError}</span>
            </div>
          </>
        )}
      </div>
      {socialNetworks.map((item: SocialNetwork, index: number) => (
        <div className="row mb-5" key={index}>
          <div className="col-12 col-md-2 mb-3 text fw-bold "></div>
          <div className="col-12 col-md-8">
            <SocialIcon socialNetwork={item} />
            <span>{item.url}</span>
          </div>
          <div className="col-12 col-md-2  d-flex justify-content-end pe-5">
            <div className="col-12 col-md-1  ">
              <div className=" btn-danger full rounded" onClick={() => removeSocial(index)}>
                <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SocialEditor;
