import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore, { setCurrentContest } from '../../../store/DslStore';
import { getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { Contest, NDAConfirm, User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { error } from 'console';

interface NDAFormProps {
  contest: Contest;
  loggedUser: User | null;
  ndaWasSigned: () => void;
  loginCallback: () => void;
}

const NDAForm: React.FC<NDAFormProps> = ({ loggedUser, contest, ndaWasSigned ,loginCallback}) => {
  const [responseError, setResponseError] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (loggedUser) {
      console.log('loggedUser', loggedUser);
      setFirstName(loggedUser.firstName || '');
      setLastName(loggedUser.lastName || '');
      setEmail(loggedUser.email || '');
    }
  }, [loggedUser]);

  const tryAccessCode = async () => {
    setErrorMessage('');
   
    if (!loggedUser){
      loginCallback();
    }
    if (!firstName || !lastName) {
      setErrorMessage('First name and Last name are required');
      return;
    }
    if (checked) {
      const data: NDAConfirm = {
        firstName: firstName,
        lastName: lastName,
      };
      sendNdaConfirm(data);
    } else {
      setResponseError(999);
    }
  };
  const sendNdaConfirm = async (data: NDAConfirm) => {
    await axiosHelper
      .sendNDAConfirm(data, contest)
      .then((response) => {
        if (response.status === 200) {
          
          ndaWasSigned();
        }
      })
      .catch((error) => {
        setResponseError(error.response.status);
        console.log(error);
        console.log(error.response.status);
        // props.auth(false);
      });
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3 ">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <span className="me-2">NDA required</span>
            <div className="bg-danger d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', height: '20px', width: '20px' }}>
              <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-10">
          You must
          {loggedUser == null && (
            <>
              {' '}
              <Link to={'#'} onClick={loginCallback}>login</Link> (or <Link to={`/signup`}>signup</Link>) and
            </>
          )}{' '}
          accept the contest NDA in order to read the full brief and join the contest or access the gallery. If you do not use your real name, your account may be suspended or
          terminated.
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3">First name</div>
        <div className="col-12 col-md-10">
          <input type="text" value={firstName || ''} onChange={(e) => setFirstName(e.target.value)}></input>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3">Last name</div>
        <div className="col-12 col-md-10">
          <input type="text" value={lastName || ''} onChange={(e) => setLastName(e.target.value)}></input>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3">Email</div>
        <div className="col-12 col-md-10">
          <input type="text" value={email || ''} disabled></input>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3">NDA</div>
        <div className="col-12 col-md-10">
          <div className="d-flex flex-row justify-content-between align-items-center ">
            <div className=" d-flex align-items-center ">
              <input type="checkbox" className="me-5" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
              <span>
                I accept the&nbsp;
                <a href={`${contest.termsAttachment}`} target="_blank" rel="noreferrer">
                  contest NDA
                </a>
              </span>
            </div>
            <div>
              <button className="btn-dark-blue confirm rounded" onClick={() => tryAccessCode()}>
                {' '}
                save
              </button>
            </div>
          </div>
          {responseError === 404 && <div className="col-12 mt-3 color-danger">Data doesn't match</div>}
          {errorMessage && (
            <div className="col-12 mt-3 color-danger">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NDAForm;
