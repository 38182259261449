import React, { useState } from 'react';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Contest, ContestTag } from '../../global';
import { useAuth } from '../../context/AuthContext';
import styles from './Brief.module.scss';
import MiniTimeLineWLF from '../../components/TimeLine/MiniTimeLineWLF';
import { useNavigate } from 'react-router-dom';
import { computeContestPhase } from '../../helpers/utilities';
import { ROUTEPATHS } from '../routePaths';

interface ContestSidePartProps {
  contest: Contest;
  disableUpload?: boolean;
  loginCallback: () => void;
  setPopupMessage: (value: boolean) => void;
}
const ContestSidePart: React.FC<ContestSidePartProps> = ({ contest, disableUpload = false, loginCallback, setPopupMessage }) => {
  const { loggedUser: user, isContestLiked, toggleContestLiked } = useAuth();

  const redirect = useNavigate();
  const [sideAnchored, setSideAnchored] = useState(false);

  const questionClicked = () => {
    if (!user) {
      loginCallback();
    } else {
      setPopupMessage(true);
    }
  };
  const uploadClicked = () => {
    if (!user) {
      loginCallback();
    } else {
      redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_UPLOAD}`);
    }
  };

  const TagButton: React.FC<ContestTag> = (item: ContestTag) => {
    if (item.name) {
      return (
        <button className="btn-tag mb-4 px-0 me-5 ">
          <span>{item.name.replace(',', '')}</span>
        </button>
      );
    } else {
      console.log('TagButton: item.name is null' + item);
      return null;
    }
  };

  if (!contest) {
    return null;
  }
  return (
    <div className={`col-12 col-md-3  px-sm-3 position-relative  pe-md-4 ${styles.sideContainer}`}>
      <SideActionsCard anchored={(anchored) => setSideAnchored(anchored)}>
        <button className={`${isContestLiked(contest) ? 'btn-danger' : 'btn-blue'} rounded full mb-3 `} onClick={() => toggleContestLiked(contest)}>
          {isContestLiked(contest) ? 'Remove from favorites' : 'Add to favorites'}
        </button>
        <button className="btn-blue rounded full mb-3" onClick={questionClicked}>
          Have a question?
        </button>
        {contest && <MiniTimeLineWLF contest={contest} />}
        {disableUpload ? (
          <></>
        ) : (
          <>
            {computeContestPhase(contest) === 'upload-phase' ? (
              <button className="btn-success big full rounded" style={{ height: '45px' }} onClick={uploadClicked}>
                <div className="btn-icon  ">
                  <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem' }} className="text color-light " />
                </div>
                <div>Upload</div>
              </button>
            ) : (
              <button className="btn-dark_grey_90 big full mb-4 rounded " style={{ height: '45px', cursor: 'default' }} disabled={true}>
                <div className="btn-icon " style={{ cursor: 'default' }}>
                  <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem', cursor: 'default' }} className="text color-light" />
                </div>
                <div>Upload</div>
              </button>
            )}
          </>
        )}
      </SideActionsCard>
      {sideAnchored && <div className=" d-none d-lg-block bg-light-gray" style={{ height: '410px' }}></div>}

      {contest && contest.tags && (
        <div className="tags mt-7 d-flex flex-wrap ">
          {contest.tags.map((item: ContestTag, index: number) => (
            <TagButton {...item} key={index} />
          ))}
        </div>
      )}

      <div className="col-12 col-md-6   position-relative">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`} target="_blank">
          <button className="btn-social__facebook full rounded mb-3 ">
            <div className="btn-icon">
              <FontAwesomeIcon icon={faFacebookF as IconProp} className="text color-light" />
            </div>
            <span className="text-lowercase">like</span>
          </button>
        </a>
      </div>
      <div className="col-12 col-md-6   position-relative">
        <a
          href={`https://twitter.com/share?hashtags=desallcom&text=${contest.name} by ${contest.userName}. Check it out on Desall.&url=https://desall.com/contest/${contest.nameForUrl}/brief&via=desallcom`}
          target="_blank"
        >
          <button className="btn-social__twitter full rounded mb-3">
            <div className="btn-icon">
              <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
              {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
            </div>
            <span className="text-lowercase">post</span>
          </button>
        </a>
      </div>
      <div className="col-12 col-md-6   position-relative">
        <a
          href={`https://pinterest.com/pin/create/button/?url=beta.altera.it/contest/${contest.name}/brief&media=${contest.image}&description=Check it out on Desall.`}
          target="_blank"
        >
          <button className="btn-social__pinterest full rounded mb-3">
            <div className="btn-icon">
              <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
            </div>
            <span className="text-lowercase">save</span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default ContestSidePart;
