import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from '../../../routes/profile/ProfileEditable.module.scss';

import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';


import { Country, Job, UploadContestUserData, User } from '../../../global';

import axiosHelper from '../../../helpers/axiosHelper';
import { DropDownJob } from '../../../components/Forms/DropDown/DropDownJob';
import { DropDownCountry } from '../../../components/Forms/DropDown/DropDownCountry';


import { useAuth } from '../../../context/AuthContext';
import DropDownJobWLF from '../../Forms/DropDown/DropDownJobWLF';
import DropDownCountryWLF from '../../Forms/DropDown/DropDownCountryWLF';

interface ProfileInfoProps {
  user: User;
  setUser?: (user: User) => void;
}

const ProfileInfoWLF: React.FC<ProfileInfoProps> = ({ user, setUser }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [mobileNumber, setMobileNumber] = useState(user.mobileNumber);
  const [addressLine1, setAddressLine1] = useState(user.addressLine1);
  const [city, setCity] = useState(user.city);
  const [state, setState] = useState(user.state);
  const [zipCode, setZipCode] = useState(user.zipCode);
  const [job, setJob] = useState(user.job);
  const [country, setCountry] = useState(user.country);
  const [jobtitle, setJobtitle] = useState(user.jobTitle);
  const [firstSchool, setFirstSchool] = useState(user.school1);
  const [freelanceLicense, setFreelanceLicense] = useState(user.freelanceLicense);
  const { register, handleSubmit, getValues } = useForm<UploadContestUserData>();

  useEffect(() => {
    const getCountries = async () => {
      let result: Country[] = await axiosHelper.getCountries();
      if (result) {
        console.log(result);
        setCountries(result);
      }
    };

    const getJobs = async () => {
      let result: Job[] = await axiosHelper.getJobs();
      if (result) {
        console.log(result);
        setJobs(result);
      }
    };

    getCountries();
    getJobs();
  }, []);

  const userObject = () => {
    const result: UploadContestUserData = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      addressLine1: addressLine1,
      city: city,
      state: state,
      zipCode: zipCode,
      job: job,
      country: country,
      jobTitle: jobtitle,
      school1: firstSchool,
      freelanceLicense: freelanceLicense,
    };
    return result;
  };

  const onSubmit = async (data: UploadContestUserData) => {
    if (job && country) {

      const userToSend = userObject();
      console.log(userToSend);
      if (userToSend) {
        await axiosHelper.updateUserForUploadContest(userToSend, user.id)?.then((response) => {
          console.log(response);
          if (response) {
            if (setUser) {
              setUser(response);
            }
          }
        });
      }
    }
  };

  function containsOnlyNumbers(str: string) {
    if (str !== '') {
      return /^\d+$/.test(str);
    }
    return true;
  }

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container   `}>
        <div className="row ">
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-12 ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row  ">
                <div className="col-12   ">
                  <div className="row mb-7 ">
                    <div className="col-12 col-md-2 text fw-bold"></div>
                    <div className="col-12 col-md-10">
                      <span className="text color-blue">
                        <i>
                          <b>
                            <span className="color-red">*</span> &nbsp; Mandatory fields (required to upload your design)
                          </b>
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5 mt-4">
                    <div className="col-12 col-md-2 text fw-bold">Username</div>
                    <div className="col-12 col-md-10">
                      <input type="text" style={{ border: '0px ' }} id="username" disabled={true} placeholder={user?.username} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      First name <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input type="text" value={firstName} {...register('firstName', { onChange: (e) => setFirstName(e.target.value) })} />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Last name <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input type="text" value={lastName} {...register('lastName', { required: true, onChange: (e) => setLastName(e.target.value) })} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Mobile
                      <br /> number <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={mobileNumber}
                        {...register('mobileNumber', {
                          onChange: (e) => (containsOnlyNumbers(e.target.value) ? setMobileNumber(e.target.value) : null),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Address <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={addressLine1}
                        {...register('addressLine1', {
                          required: true,
                          onChange: (e) => setAddressLine1(e.target.value),
                        })}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      City <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={city}
                        {...register('city', {
                          required: true,
                          onChange: (e) => setCity(e.target.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      State <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input type="text" value={state} {...register('state', { required: true, onChange: (e) => setState(e.target.value) })} />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Zip Code <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input type="text" value={zipCode} {...register('zipCode', { required: true, onChange: (e) => setZipCode(e.target.value) })} />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Who are you <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                      <DropDownJobWLF options={jobs} setSelectedJob={setJob} selectedJob={job} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Your current country <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                      <DropDownCountryWLF options={countries} setSelectedCountry={setCountry} selectedCountry={country} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Job title <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={jobtitle}
                        {...register('jobTitle', {
                          required: true,
                          onChange: (e) => setJobtitle(e.target.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      School/ University <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={firstSchool}
                        {...register('school1', {
                          required: true,
                          onChange: (e) => setFirstSchool(e.target.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      License to work as freelance <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                      <div>
                        <input type="radio" onChange={() => setFreelanceLicense('Y')} checked={freelanceLicense === 'Y'} />
                        <label className="align-self-middle fw-normal ">Yes</label>
                      </div>
                      <div>
                        <input type="radio" onChange={() => setFreelanceLicense('N')} checked={freelanceLicense === 'N'} />
                        <label className="align-self-middle fw-normal ">No</label>
                      </div>
                    </div>
                  </div>

                  <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
                    <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfileInfoWLF;
