import { Contest, ContestsILike, ContestStatus, TimelineData, User } from '../global';
import { Datestr, registrationDateString, startDateString } from './utils';

export function dateColorForStatus(contest: Contest): string {
  const status = computeContestStatus(contest);
  switch (status) {
    case 'closed':
      return 'danger';
    case 'ongoing':
      return 'success';
    case 'client-vote':
      return 'dark_yellow';
    case 'community-vote':
      return 'dark_yellow';
    case 'coming-soon':
      return 'light_grey_40';
    default:
      return 'black';
  }
}

export function getContestStatusText(contest: Contest): string {
  const status = computeContestStatus(contest);
  if (status === 'ongoing') {
    return `Ends:  ${registrationDateString(contest.endUploadDate)}`;
  }
  if (status === 'closed') {
    return `closed: ${registrationDateString(contest.closeDate)}`;
    // if (contest.assignmentDate) {
    //   return `closed: ${registrationDateString(contest.assignmentDate)}`;
    // } else {
    //   return `closed: ${registrationDateString(contest.endUploadDate)}`;
    // }
  }
  return status.replace('-', ' ');
}
export function computeContestStatus(contest: Contest | ContestsILike): ContestStatus {
  const date = new Date().getTime();
  // if (contest.contestType !== 'Co-creation') {
  if (contest.isClosed) {
    return 'closed';
  } else if (date < new Date(contest.startDate).getTime()) {
    return 'coming-soon';
  } else if (date < new Date(contest.endUploadDate).getTime()) {
    return 'ongoing';
  } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime()) {
    return 'community-vote';
  } else if (contest.endVotingDate && date > new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
    return 'client-vote';
  } else if (date > new Date(contest.endUploadDate).getTime() && date < new Date(contest.hypotheticalEndDate).getTime() && !contest.isClosed) {
    return 'client-vote';
  } else if (date > new Date(contest.endUploadDate).getTime() && !contest.isClosed) {
    return 'client-vote';
  }
  return 'standby';
}

export function computeContestPhase(contest: Contest | ContestsILike) {
  const date = new Date().getTime();
  // if (contest.contestType !== 'Co-creation') {
  if (contest.isClosed) {
    return 'closed';
  } else if (date < new Date(contest.startDate).getTime()) {
    return 'coming-soon';
  } else if (date < new Date(contest.endUploadDate).getTime()) {
    return 'upload-phase';
  } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime()) {
    return 'community-vote';
  } else if (contest.endVotingDate && date > new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
    return 'client-vote';
  } else if (date > new Date(contest.endUploadDate).getTime() && !contest.isClosed) {
    return 'client-vote';
  }
  return 'standby';
}

export function progressNumber(contest: Contest): number {
  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(contest.startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(contest.endUploadDate) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(contest.endVotingDate) / 86400000);
  let progressNumber = 100;
  // if (contest.contestType !== 'Co-creation') {
  if (computeContestStatus(contest) === 'ongoing') {
    let tot = endDateNumber - startDateNumber;
    let passed = date - startDateNumber;
    progressNumber = (passed * 100) / tot;
    // console.log(tot, passed, progressNumber);
  } else if (computeContestStatus(contest) === 'community-vote') {
    let tot = endVotingDateNumber - endDateNumber;
    let passed = date - endDateNumber;
    progressNumber = (passed * 100) / tot;
  }
  return progressNumber;
}

// setTimelines(contest.startDate, contest.endUploadDate, contest.endVotingDate, contest.hypotheticalEndDate, contest);

export function calculateTimelines(contest: Contest) {
  console.log('################# CALCULATE TIMELINES');
  console.log(contest);
  console.log(contest.endUploadDate);
  console.log(contest.endVotingDate);
  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(contest.startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(contest.endUploadDate) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(contest.endVotingDate) / 86400000);
  var result: TimelineData = {
    contestTimelineTotUpload: 0,
    contestTimelinePassedUpload: 0,
    contestTimelineTotCommunityVote: 0,
    contestTimelinePassedCommunityVote: 0,
    contestTimelineTotClientVote: 0,
    contestTimelinePassedClientVote: 0,
  };
  if (startDateNumber && endDateNumber) {
    result.contestTimelineTotUpload = endDateNumber - startDateNumber;
    if (date > endDateNumber) {
      result.contestTimelinePassedUpload = endDateNumber - startDateNumber;
    } else {
      result.contestTimelinePassedUpload = date - startDateNumber;
    }

    if (contest.endVotingDate) {
      result.contestTimelineTotCommunityVote = endVotingDateNumber - endDateNumber;
      if (endVotingDateNumber > date) {
        result.contestTimelinePassedCommunityVote = date - endDateNumber;
      } else if (date > endVotingDateNumber) {
        result.contestTimelinePassedCommunityVote = endVotingDateNumber - endDateNumber;
        result.contestTimelineTotClientVote = 50;
        result.contestTimelinePassedClientVote = date - endVotingDateNumber;
        if (contest.isClosed) {
          result.contestTimelineTotClientVote = 100;
          result.contestTimelinePassedClientVote = 100;
        }
      }
    } else {
      result.contestTimelineTotClientVote = 50;
      result.contestTimelinePassedClientVote = date - endDateNumber;
      if (date >= endDateNumber) {
        result.contestTimelineTotClientVote = 50;
        result.contestTimelinePassedClientVote = date - endDateNumber;
      }
      if (contest.isClosed) {
        result.contestTimelineTotClientVote = 100;
        result.contestTimelinePassedClientVote = 100;
      }
    }
  }

  return result;
}

export function checkUserForUpload(user: User): boolean {
  if (!user) {
    return false;
  }
  if (user.userType === 'G') return true;
  if (
    user.username &&
    user.firstName &&
    user.lastName &&
    user.mobileNumber &&
    user.city &&
    user.state &&
    user.zipCode &&
    user.country &&
    user.job &&
    user.jobTitle &&
    user.school1 &&
    user.freelanceLicense
  ) {
    return true;
  }

  return false;
}
