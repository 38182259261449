import React, { useState, useRef } from 'react';

import styles from './DropDown.module.scss';
import { Country } from '../../../global';

interface Props {
  options: Country[];
  selectedCountry?: Country;
  setSelectedCountry: (c: Country) => void;
}

const DropDownCountryWLF: React.FC<Props> = ({ options, selectedCountry, setSelectedCountry }) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);

  const isSelected = (option: Country) => {
    return selectedCountry && selectedCountry.id === option.id ? styles.selected : '';
  };

  const selectCountry = (country: Country) => {
    setSelectedCountry(country);
    setOpen(false);
  };

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper_year}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={() => setOpen(!open)}>
          <span className={`${styles.dropdown__label} w-75`}>{selectedCountry ? selectedCountry.name : 'Choose'}</span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {options.map((option, index) => (
            <div key={index} className={`${styles.dropdown__option} ${isSelected(option)}`} data-value={option.name} onClick={() => selectCountry(option)}>
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default DropDownCountryWLF;
