import React, { useEffect, useState } from 'react';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerSquare.module.scss';
import { User } from '../../../global';
import { useNavigate } from 'react-router-dom';
import SquareInfo from './SquareInfo/SquareInfo';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { useAuth } from '../../../context/AuthContext';

interface IDesignerSquare {
  user: User;
  children?: React.ReactNode;
}

const DesignerSquare: React.FC<IDesignerSquare> = ({ user }) => {
  const redirect = useNavigate();
  const { loggedUser, isUserLiked, toggleUserLiked } = useAuth();

  const goToDetail = async () => {
    redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
  };

  return (
    <div className={`${styles.square}  position relative`}>
      <SquareInfo user={user}>
        <div className="text-center ">
          {!isUserLiked(user) ? (
            <ActionButton
              label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
              color={!loggedUser ? 'light-gray-3' : ''}
              icon="addFavorite"
              position="top-right"
              action={() => toggleUserLiked(user)}
            />
          ) : (
            <ActionButton label="Remove from favorites" icon="confirm" position="top-right" color="success" action={() => toggleUserLiked(user)} />
          )}
          <div className="container bg-light justify-content-center">
            <div className={`${styles.rowTitle} row  `}>
              <div className="col  text-center align-self-center  mb-3 " style={{ overflow: 'hidden', cursor: 'pointer' }} onClick={() => goToDetail()}>
                <span className="fw-bold text-16 color-blue d-none d-md-block ">{user.username}</span>
                <span className="fw-bold text-16 color-blue d-md-none d-sm-block ">{user.username}</span>
              </div>
            </div>
          </div>
        </div>
      </SquareInfo>
    </div>
  );
};

export default DesignerSquare;
