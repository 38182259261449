import React, { useEffect, useState } from 'react';
import SquaredImage from '../../../SquaredImage/SquaredImage';

import styles from './SquareInfo.module.scss';
import { User } from '../../../../global';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ROUTEPATHS } from '../../../../routes/routePaths';
import { cleanupHttpPath } from '../../../../helpers/utils';
export interface ICardInfo {
  user: User;
  children?: React.ReactNode;
}

const SquareInfo: React.FC<ICardInfo> = ({ user, children }) => {
  const redirect = useNavigate();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (user.profilePic) {
      setImage(cleanupHttpPath(user.profilePic));
    }
  }, [user]);

  const goToDetail = async () => {
    console.log('SquareInfo GO TO DETAIL');

    redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
  };

  return (
    <div className={`${styles.cardInfoWrapper}  d-flex flex-column bg-light position-relative`}>
      <div className={`${image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light px-5 pt-4`} onClick={() => goToDetail()} style={{ cursor: 'pointer' }}>
        {image ? (
          <SquaredImage image={image} altText={user.username} circle={true} />
        ) : (
          <div className={`${styles.squaredImageWrapper}`}>
            <div className={`${styles.imageInner}`}>
              <FontAwesomeIcon icon={faUser as IconProp} className="color-light " />
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.cardInfoContent} bg-light`}>{children}</div>
    </div>
  );
};

export default SquareInfo;
