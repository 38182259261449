import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore from '../../../store/DslStore';

import styles from './PopUpSendMessage.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons';

import axiosHelper from '../../../helpers/axiosHelper';
import { Modal } from 'react-bootstrap';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { getCurrentContest, getCurrentDesign, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { useForm } from 'react-hook-form';
import { InappropriateForm, SendMessageData } from '../../../global';
import { border } from '@mui/system';

interface PopUpSendMessageProps {
  popup: boolean;
  close: () => void;
  setMessageNumber: (message: number) => void;
  recipient: string;
  contestId?: number;
}

const PopUpSendMessage: React.FC<PopUpSendMessageProps> = (props) => {
  const currentContest = useStoreState(DslStore, getCurrentContest);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [message, setMessage] = useState();
  const [popup, setPopup] = useState(props.popup);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SendMessageData>();

  const onSubmit = (data: SendMessageData) => {
    data.recipient = props.recipient;
    data.contestId = props.contestId;
    console.log(data);
    if (currentUser) {
      axiosHelper
        .sendMessage(data)
        .then(
          (response) => (
            console.log(response),
            // alert(response.name + ' flagged as inappropriate'),
            props.setMessageNumber(1),
            props.close && props.close,
            setPopup(false)
          )
        )
        .catch((error) => {
          console.log(error);
          props.setMessageNumber(2);
        });
    }
  };

  return (
    <>
      <Modal
        show={popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
            <Modal.Body className="d-flex justify-content-center p-3">
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <div className="row justify-content-center align-items-center  ">
                <div className="col-12 mb-3">
                  <span className="text-16 fw-bold "></span>
                </div>
                <div className="d-flex col-12 flex-row align-items-center mb-5">
                  <div className="col-3  tex-start  d-flex flex-row justify-content-start ps-3">
                    <span className="text-16 "> Recipient</span>
                  </div>
                  <div className="col-9  ">
                    <input type="text" value={props.recipient} disabled style={{ border: '0px' }} />
                  </div>
                </div>

                <div className="d-flex  col-12  mb-5  ">
                  <div className="d-flex col-3  flex-column align-items-start ">
                    <span className="text-16 ps-3">Message</span>
                  </div>
                  <div className="d-flex col-9 pe-3">
                    <textarea value={message} {...register('message', { onChange: (e) => setMessage(e.target.value) })} />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12  d-flex justify-content-between ">
                    <div className="col-3 ps-1  d-flex  ">
                      <div className="d-flex justify-content-center align-items-center ">
                        <div
                          className={` ${styles.closeButton}`}
                          onClick={() => {
                            setPopup(!popup);
                            props.close();
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                        </div>
                        <span className="ps-3">Close</span>
                      </div>
                    </div>
                    <div className="col-4 pe-1 d-flex   ">
                      <button
                        className={`btn-success rounded full 
                      
                     mb-3`}
                        onClick={() => console.log('manda messaggio')}
                      >
                        send message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </Modal.Body>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PopUpSendMessage;
