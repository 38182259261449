import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  faBehanceSquare,
  faDribbbleSquare,
  faFacebookSquare,
  faFlickr,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
  faSquareXTwitter,
  faTumblrSquare,
  faTwitterSquare,
  faVimeoSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { library, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import React from 'react';
import { SocialNetwork } from '../../global';

interface Props {
  socialNetwork: SocialNetwork;
}

const SocialIcon: React.FC<Props> = ({ socialNetwork }) => {
  library.add(
    faBehanceSquare,
    faDribbbleSquare,
    faFacebookSquare,
    faFlickr,
    faGooglePlusSquare,
    faInstagramSquare,
    faLinkedin,
    faPinterestSquare,
    faSquareXTwitter,
    faTumblrSquare,
    faTwitterSquare,
    faVimeoSquare,
    faYoutubeSquare,
    faLink
  );
  const getIconByName = (iconName: string) => {
    const prefix: IconPrefix = iconName === 'link' ? 'fas' : 'fab'; // Use 'fas' for "link", 'fab' for brand icons
    const iconLookup: IconLookup = { prefix, iconName: iconName as IconName };
    return findIconDefinition(iconLookup);
  };

  return (
    <span>
      <FontAwesomeIcon
        icon={getIconByName(socialNetwork.socialNetworkFinder.iconName)}
        className=" text color-blue me-3 cursor-pointer"
        style={{ fontSize: '2rem' }}
        onClick={() => window.open(socialNetwork.url, '_blank')} // Example: Open URL on click
      />
    </span>
  );
};

export default SocialIcon;
