export const joinedStrings = (firstString?: string, secondString?: string) => {
  let result = '';
  if (firstString) {
    result = firstString;
  }
  if (secondString) {
    if (result.length > 0) {
      result += ' ' + secondString;
    } else {
      result = secondString;
    }
  }
  if (result.length > 0) {
    return result;
  }
  return '-';
};

export const dateFromISOString = (dateString?: string) => {
  if (dateString) {
    var parsedDate: Date = new Date(Date.parse(dateString));
    return parsedDate.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
    return ' ';
  }
};
