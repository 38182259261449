import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './custom.scss';
import { AuthProvider } from './context/AuthContext';

// ROUTES

import App from './App';
import { SWRConfig } from 'swr';
import { Cache } from 'swr';

function localStorageProvider(cache: Readonly<Cache<any>>): Cache<any> {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('app-cache', appCache);
  });

  // We still use the map for write & read for performance.
  return map as Cache<any>;
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <AuthProvider>
      <SWRConfig value={{ provider: localStorageProvider, revalidateOnFocus: false }}>
        <App />
      </SWRConfig>
    </AuthProvider>
);
