import React, { useEffect } from 'react';
import styles from './YourProfile/YourProfile.module.scss';

interface PEAccordionProps {
  title: string;
  id: number;
  activeid: number;
  click: (id: number) => void;
  component: React.ReactNode;
}

const PEAccordion: React.FC<PEAccordionProps> = ({ title, id, activeid, click, component }) => {
  useEffect(() => {
    console.log('PEAccordionProps');
  }, []);

  return (
    <>
      <div id = {`accordion${id}`}
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => click(id)}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            {title}
          </span>
        </div>

        <div className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${id === activeid && styles.open}`}>
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {activeid === id && component}
    </>
  );
};

export default PEAccordion;
