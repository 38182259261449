import React, { useEffect, useState } from 'react';
import styles from './ProfileEditable.module.scss';

import { ContestTag, Skill, User } from '../../global';

import UpdatePassword from '../../components/ProfileEditable/UpdatePassword/UpdatePassword';
import YourProfile from '../../components/ProfileEditable/YourProfile/YourProfile';
import YourProfessionalProfile from '../../components/ProfileEditable/YourProfessionalProfile/YourProfessionalProfile';
import PEAccordion from '../../components/ProfileEditable/PEAccordion';
import NewsletterNotifications from '../../components/ProfileEditable/Newsletter&Notifications/NewsletterNotifications';
import { useForm } from 'react-hook-form';

interface ProfileProps {
  user: User;
  callback: (user: User) => void;
  breadcrumbs?: (breadcrumbs: string) => void;
  successBanner: (success: number) => void;
}

const ProfileEditable: React.FC<ProfileProps> = ({ user, callback, breadcrumbs, successBanner }) => {


  const [visibleEditor, setVisibleEditor] = useState(1);
  const ignoreFirst = React.useRef(true);
  const scrollToDiv = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (visibleEditor >= 0) {
      if (ignoreFirst.current) {
        ignoreFirst.current = false;
        return;
      }
      scrollToDiv('accordion' + visibleEditor);
      setTimeout(() => {
        const offset = 100; // Change this value to adjust the scroll offset
        window.scrollBy(0, -offset); // Scroll up by 50px (negative value)
      }, 200); // Timeout to ensure smooth scroll finishes
    }
  }, [visibleEditor]);

  const toggleVisibleAccordion = (id: number) => {
    if (visibleEditor === id) {
      setVisibleEditor(0);
    } else {
      setVisibleEditor(id);
    }
  };
  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container  px-sm-0  `}>
        <div className="row ">
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-3 pt-5 ">
            {user.skills.length > 0 ? (
              <>
                {user.skills &&
                  user.skills.map((item: Skill, index: number) => (
                    <button key={index} className="btn-tag mb-4 px-0 me-5 ">
                      <span>{item.name}</span>
                    </button>
                  ))}
              </>
            ) : (
              <>
                {user.tags &&
                  user.tags.map((item: ContestTag, index: number) => (
                    <button key={index} className="btn-tag mb-4 px-0 me-5 ">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  ))}
              </>
            )}
          </div>
          <div className="col-12 col-md-9">
            <div className="row pt-5">
              <div className="col-12 ps-5  ">
                  <PEAccordion title="Your profile" id={1} activeid={visibleEditor} click={toggleVisibleAccordion} component={<YourProfile user={user} onChange={callback} />} />
                  <PEAccordion
                    title="Your professional profile"
                    id={2}
                    activeid={visibleEditor}
                    click={toggleVisibleAccordion}
                    component={<YourProfessionalProfile user={user} onChange={callback} />}
                  />
                  <PEAccordion
                    title="Newsletter and notifications"
                    id={3}
                    activeid={visibleEditor}
                    click={toggleVisibleAccordion}
                    component={<NewsletterNotifications user={user} onChange={callback} />}
                  />
                  <PEAccordion title="Update password" id={4} activeid={visibleEditor} click={toggleVisibleAccordion} component={<UpdatePassword user={user} />} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfileEditable;
