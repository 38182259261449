import React, { useEffect, useState } from 'react';
import { Country } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import styles from './DropDown.module.scss';

interface CountrySelectProps {
  value?: Country;
  onChange: (country: Country) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({ value, onChange }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    let result: Country[] = await axiosHelper.getCountries();
    if (result) {
      console.log(result);
      setCountries(result);
    }
  };

  const isSelected = (option: Country) => {
    return value && value.id === option.id ? styles.selected : '';
  };

  const selectCountry = (country: Country) => {
    onChange(country);
    setOpen(false);
  };

  return (
    <div className={`${styles.dropdown_wrapper_year}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={() => setOpen(!open)}>
          <span className={`${styles.dropdown__label} w-75`}>{value ? value.name : 'Select country...'}</span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {countries.map((option, index) => (
            <div key={index} className={`${styles.dropdown__option} ${isSelected(option)}`} data-value={option.name} onClick={() => selectCountry(option)}>
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  // return (
  //   <select value={value?.id || ''} onChange={handleCountryChange}>
  //     <option value="">Select a country</option>
  //     {countries.map((country) => (
  //       <option key={country.id} value={country.id}>
  //         {country.name}
  //       </option>
  //     ))}
  //   </select>
  // );
};

export default CountrySelect;
