import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Brief.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCloudArrowUp, faUserPlus, faFileArrowDown, faPlus, faExclamation, faRemove, faMedal } from '@fortawesome/free-solid-svg-icons';

//Header Data

//Footer Data

import { getCoCreationContests } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setCoCreationContest, setCoCreationContestStats } from '../../store/DslStore';
import { finalString, fullPathLogo, revisionDateString, quadrettoDateHours, quadrettoDateString, awardTitleString } from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';

import { CoCreationContest, CoCreationContestStats, Contest, ContestGuideline, RevisionUpload, User } from '../../global';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CoCreationBrief from './CoCreationBrief';
import { faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
// import rehypeFilter from 'react-markdown/lib/rehype-filter';
import { useAuth } from '../../context/AuthContext';
import ContestSidePart from './ContestSidePart';
import { ROUTEPATHS } from '../routePaths';
import BriefPiece from '../../components/Brief/BriefPiece';
import NDAForm from '../../components/Contest/NDAForm/NDAForm';
import AccessCodeForm from '../../components/Contest/AccessCodeForm/AccessCodeForm';
import { computeContestPhase } from '../../helpers/utilities';
interface ContestPropsWLF {
  contest: Contest;
  loggedUser: User | null;
  loginCallback: () => void;
  setPopupMessage: (message: boolean) => void;
  refreshContest: () => void;
}

const BriefWLF: React.FC<ContestPropsWLF> = ({ contest, loggedUser, refreshContest, loginCallback, setPopupMessage }) => {
  const { isContestLiked, toggleContestLiked } = useAuth();

  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const [contestGuidelines, setContestGuidelines] = useState<ContestGuideline[]>([]);

  const [revisionLength, setRevisionLength] = useState(0);
  const { nameForUrl } = useParams();
  const [revisionOpen, setRevisionOpen] = useState(false);
  const [revisionError, setRevisionError] = useState(false);
  const [responseRevision, setResponseRevision] = useState();
  const redirect = useNavigate();
  const date = new Date().toISOString();
  const [hiddenDate, setHiddenDate] = useState('');
  const [key, setKey] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RevisionUpload>();

  useEffect(() => {
    axiosHelper.getContestGuidelines(contest.id).then(
      (response) => {
        setContestGuidelines(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [contest.id]);

  const hidden = (startDate: string, endUploadDate?: string) => {
    if (endUploadDate && startDate && !contest.isPrivate && !contest.hideGalleryTab) {
      const date = new Date().getTime();
      const startdateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endUploadDate).getTime();

      if (Math.floor((endDateTime - startdateTime) / 2 / 86400000) > Math.floor((date - startdateTime) / 86400000)) {
        let ciccio = startdateTime + Math.floor((endDateTime - startdateTime) / 2);
        setHiddenDate(new Date(ciccio).toUTCString());
      }
    }
  };

  const openEngFile = () => {
    const element = document.createElement('a');
    element.href = `${contest.fileAttachment.replace('http://s3-eu-west-1.amazonaws.com:80/', 'https://s3-eu-west-1.amazonaws.com/')}`;
    element.target = '_blank';
    element.click();
  };

  const openItaFile = () => {
    const element = document.createElement('a');
    element.href = `${contest.fileAttachmentIta.replace('http://s3-eu-west-1.amazonaws.com:80/', 'https://s3-eu-west-1.amazonaws.com/')}`;
    element.target = '_blank';
    element.click();
  };

  const openBriefFile = (path: string) => {
    const element = document.createElement('a');
    element.href = `${fullPathLogo(path)}`;
    element.target = '_blank';
    element.click();
  };

  const downloadKit = () => {
    const element = document.createElement('a');
    element.href = `${contest.kitAttachment.replace('http://s3-eu-west-1.amazonaws.com:80/', 'https://s3-eu-west-1.amazonaws.com/')}`;
    element.download = `${contest.kitAttachment.replace('http://s3-eu-west-1.amazonaws.com:80/', 'https://s3-eu-west-1.amazonaws.com/')}`;
    element.target = '_blank';
    element.click();
  };

  const handleRevisionLength = (e: any) => {
    let revision = e.target.value;
    setRevisionLength(revision.length);
  };

  const publishRevision = async (revision: string) => {
    if (nameForUrl) {
      console.log('publish');
      if (loggedUser?.id === contest.userId) {
        // console.log('ciao');
        if (revision.length > 0) {
          console.log(contest.revision1, contest.revision2);
          if (contest.revision1 === null) {
            console.log('revision1');
            await axiosHelper
              .revision1Contest(revision, contest.id)
              ?.then((response) => {
                // fetchContestByName(nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          } else if (contest.revision2 === null) {
            console.log('revision2');
            await axiosHelper
              .revision2Contest(revision, contest.id)
              .then((response) => {
                // fetchContestByName(nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          }
        } else {
          console.log('no revision');
        }
      } else {
        console.log('no user');
      }
    }
  };

  const coCreationContestStats = () => {
    let stats: CoCreationContestStats = {
      entries: 0,
      votes: 0,
      inspirations: contest.inspirationCount ? contest.inspirationCount : 0,
      awards: 0,
    };
    cocreationContests?.map(
      (item: CoCreationContest) => (
        // console.log(item.sumVotesPublicDesigns),
        item.publicDesignsOrderedCount ? (stats.entries = stats.entries + item.publicDesignsOrderedCount) : null,
        item.realTotalAwards ? (stats.awards = stats.awards + item.realTotalAwards) : null,
        item.sumVotesPublicDesigns ? (stats.votes = stats.votes + item.sumVotesPublicDesigns) : null
      )
    );
    setCoCreationContestStats(stats);
  };

  const checkRevision = (revision: string) => {
    console.log(revision.length);
    if (revisionLength > 1000) {
      setRevisionError(true);
    } else {
      publishRevision(revision);
    }
  };

  const onSubmit = (data: any) => {
    console.log(data.revision);
    checkRevision(data.revision);
  };

  // const fetchContestByName = (contestName: string) => {
  //   // console.log('BY NAME');
  //   if (contestName) {
  //     axiosHelper
  //       .retrieveContestByName(contestName)
  //       .then((response) => {
  //         // console.log(response);
  //         setCurrentContest(response.data);
  //       })
  //       .catch((error) => {
  //         throw error;
  //       });
  //   }
  // };

  const contestrevision = () => {
    console.log(contest.revision1, contest.revision2);
    if (contest.revision2 !== null) {
      return false;
    } else if (contest.revision1 !== null) {
      return true;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (contest.contestType === 'Co-creation') {
      getCoCreationContestDetails(contest.id);
    }
    if (contest.endUploadDate && contest.startDate) {
      hidden(contest.startDate, contest.endUploadDate);
    }
    setKey(key + 1);
  }, [contest]);

  useEffect(() => {
    coCreationContestStats();
  }, cocreationContests);

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  return (
    <>
      <div className="bg-light-gray  px-0">
        <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
          {/* Body ====================================================================== */}
          <div className="row ">
            <ContestSidePart contest={contest} loginCallback={loginCallback} setPopupMessage={setPopupMessage} />

            <div className="col-12 col-md-9 pt-5 px-md-3  px-sm-4 ">
              {contest.contestType !== 'Co-creation' && (
                <div className="row">
                  <div className="col-12 px-3">
                    <div className="row mb-5">
                      <div className="col-12 sub-title fw-bold mb-3">Contest typology</div>
                      <div className="col-12">
                        <span className="text-16">
                          <span className="color-blue me-2 cursor-pointer" onClick={() => redirect('/For_Creatives/How-to-participate')}>
                            Single phase
                          </span>
                          {contest.category && <> - {finalString(contest.category)} contest</>}
                        </span>
                      </div>
                    </div>
                    <BriefPiece summary="Summary" textDescription={contest.textDescription} />
                    {loggedUser ? (
                      <>
                        {contest.needsAccessCode && (
                          <AccessCodeForm
                            contest={contest}
                            loggedUser={loggedUser}
                            loginCallback={loginCallback}
                            accessCodeWasValid={() => {
                              refreshContest();
                            }}
                          />
                        )}
                        {contest.needsNDA && (
                          <NDAForm
                            contest={contest}
                            loggedUser={loggedUser}
                            loginCallback={loginCallback}
                            ndaWasSigned={() => {
                              refreshContest();
                            }}
                          />
                        )}
                        {!contest.needsAccessCode && !contest.needsNDA && (
                          <>
                            <BriefPiece summary="Company description" textDescription={contest.userDescr} />

                            {contest.fileAttachment || contest.kitAttachment || contest.fileAttachmentIta ? (
                              <div className="row mb-5">
                                <div className="col-12 sub-title fw-bold mb-3">Contest materials</div>
                                {contest.fileAttachmentIta ? (
                                  <div className="col-12 col-md-4 ">
                                    <button className="btn-dark-blue-materials full mb-4 rounded" onClick={openItaFile}>
                                      <div className="btn-icon">
                                        <FontAwesomeIcon icon={faFileArrowDown as IconProp} style={{ fontSize: '1.75rem' }} className="text color-light" />
                                      </div>
                                      <div>BRIEF ITA (.pdf)</div>
                                    </button>
                                  </div>
                                ) : null}
                                {contest.fileAttachment ? (
                                  <div className="col-12 col-md-4">
                                    <button className="btn-dark-blue-materials  full mb-4 rounded " onClick={openEngFile} disabled={contest.fileAttachment ? false : true}>
                                      <div className="btn-icon">
                                        <FontAwesomeIcon icon={faFileArrowDown as IconProp} style={{ fontSize: '1.75rem' }} className="text color-light" />
                                      </div>
                                      <div>BRIEF ENG (.pdf)</div>
                                    </button>
                                  </div>
                                ) : null}
                                {contest.kitAttachment ? (
                                  <div className="col-12 col-md-4">
                                    <button className="btn-dark-blue-materials  full mb-4 rounded " onClick={downloadKit} disabled={contest.kitAttachment ? false : true}>
                                      <div className="btn-icon">
                                        <FontAwesomeIcon icon={faFileArrowDown as IconProp} style={{ fontSize: '1.75rem' }} className="text color-light" />
                                      </div>
                                      <div>MATERIAL FILES (.zip)</div>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            {contest.brief && <BriefPiece summary="What we need" textDescription={contest.brief} />}

                            {contestGuidelines.map((item: ContestGuideline, index: number) => (
                              <BriefPiece key={index} summary={item.guideline.name} textDescription={item.textDescription} />
                            ))}
                            {contest.customName && contest.customDescription && (
                              <div className="row mb-5">
                                <div className="col-12 col-md-3 text-16 fw-bold mb-3">{contest.customName}</div>
                                <div className="col-12 col-md-9">
                                  <ReactMarkdown className="text-16" children={finalString(contest.customDescription)} remarkPlugins={[remarkGfm]} />
                                </div>
                              </div>
                            )}
                            {
                              <>
                                <div className="row mb-5">
                                  <div className="col-12 sub-title fw-bold mb-3">Awards</div>
                                  <div className="col-12 d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-between ">
                                    {contest.awards && contest.awards.map((item, index) => (
                                      <div
                                        key={index}
                                        className=" col-12 col-md-6 mb-4 d-flex flex-row align-items-center justify-content-center  bg-dark-cyan  "
                                        style={{ height: '45px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-2 h-100  d-flex flex-row align-items-center justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faMedal as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-4 h-100  d-flex flex-row align-items-center justify-content-start">
                                          <span className="text-16 fw-bold color-light ">{awardTitleString(item.title, item.pos)}</span>
                                        </div>
                                        <div className="col-6 h-100  d-flex flex-row align-items-center justify-content-center">
                                          <span className="sub-title  color-light ">
                                            {item.price !== 0 ? `€${item.price}` : `-`}
                                            {`${item.hasRoyalties ? (item.price !== 0 ? ' + R' : 'ROYALTIES') : ''}`}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {contest.underAwards && <BriefPiece summary="" textDescription={contest.underAwards} />}
                                <div className="row mb-5">
                                  <div className="col-12 sub-title fw-bold mb-3">Key dates</div>

                                  <div className="col-12 d-flex flex-column flex-wrap flex-md-row align-items-center justify-content-between ">
                                    {contest.endUploadDate && (
                                      <div
                                        className=" col-12 col-md-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                        style={{ height: '120px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faCalendarXmark as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-10 h-100  d-flex flex-column align-items-start justify-content-center">
                                          <div className=" h-25 mb-3  w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                              Submission <br />
                                              deadline
                                            </span>
                                          </div>
                                          <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="sub-title fw-bold color-light text-center ">
                                              {quadrettoDateString(contest.endUploadDate)}
                                              <br />
                                              {/* {new Date(contest.endUploadDate).toLocaleTimeString()} */}
                                              {quadrettoDateHours(contest.endUploadDate)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {contest.endUploadDate && hiddenDate !== '' && (
                                      <div
                                        className=" col-12 col-md-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                        style={{ height: '120px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faCalendarXmark as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                          <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                              Hidden option <br />
                                              deadline
                                            </span>
                                          </div>
                                          <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="sub-title fw-bold color-light text-center ">
                                              {quadrettoDateString(hiddenDate)}
                                              <br />
                                              {/* {new Date(contest.endUploadDate).toLocaleTimeString()} */}
                                              {quadrettoDateHours(hiddenDate)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {contest.conceptDeadlineDate && (
                                      <div
                                        className=" col-12 col-md-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                        style={{ height: '120px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faCalendarXmark as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                          <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text h-100 fw-bold color-light  pt-2 text-center h-25 mb-3">
                                              Concept review <br />
                                              deadline
                                            </span>
                                          </div>
                                          <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="sub-title fw-bold color-light text-center">
                                              {quadrettoDateString(contest.conceptDeadlineDate)}
                                              <br />
                                              {/* {new Date(contest.conceptDeadlineDate).toLocaleTimeString()} */}
                                              {quadrettoDateHours(contest.conceptDeadlineDate)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {contest.endVotingDate && (
                                      <div
                                        className=" col-12 col-md-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                        style={{ height: '120px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faCalendarXmark as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                          <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                              Community vote <br />
                                              deadline
                                            </span>
                                          </div>
                                          <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="sub-title fw-bold color-light text-center">
                                              {quadrettoDateString(contest.endVotingDate)}
                                              <br />
                                              {/* {new Date(contest.endVotingDate).toLocaleTimeString()} */}
                                              {quadrettoDateHours(contest.endVotingDate)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {contest.hypotheticalEndDate && (
                                      <div
                                        className=" col-12 col-md-6 mb-4 d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                        style={{ height: '120px', borderRadius: '8px', maxWidth: '420px' }}
                                      >
                                        <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                          <span className="text color-light ">
                                            <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faCalendarXmark as IconProp} color="#ffffff" className="text" />
                                          </span>
                                        </div>
                                        <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                          <div className="h-25  w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                              Winner <br />
                                              announcment
                                            </span>
                                          </div>
                                          <div className=" h-75 w-100 d-flex flex-row align-items-center justify-content-center">
                                            <span className="text fw-bold color-light text-center ">
                                              approximately by
                                              <br />
                                              <span className="sub-title">
                                                {quadrettoDateString(contest.hypotheticalEndDate)} &nbsp;
                                                {/* {new Date(contest.hypotheticalEndDate).toLocaleTimeString()} */}
                                                {quadrettoDateHours(contest.hypotheticalEndDate)}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {contest.underTimeline && <BriefPiece summary="" textDescription={contest.underTimeline} />}
                              </>
                            }
                            {contest.revision1 && (
                              <div className="row mb-5">
                                <div className="col-12 sub-title fw-bold mb-3">
                                  Brief revision #1
                                  <div className=" ms-4 bg-danger d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', height: '20px', width: '20px' }}>
                                    <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <p className="text-16">{revisionDateString(contest.revision1Date)}</p>
                                  <span>
                                    <ReactMarkdown className="text-16" children={finalString(contest.revision1)} remarkPlugins={[remarkGfm]} />
                                  </span>
                                </div>
                              </div>
                            )}

                            {contest.revision2 && (
                              <>
                                <div className="row mb-5">
                                  <div className="col-12 sub-title fw-bold mb-3">
                                    Brief revision #2
                                    <div
                                      className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                                      style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                                    >
                                      <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <p className="text-16">{revisionDateString(contest.revision2Date)}</p>
                                    <span>
                                      <ReactMarkdown className="text-16" children={finalString(contest.revision2)} remarkPlugins={[remarkGfm]} />
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            {loggedUser.userType === 'C' && loggedUser.id === contest.userId && contestrevision() && (
                              <>
                                <div className="row mb-5">
                                  <div className="col-12 col-md-8"></div>
                                  <div className="col-12 col-md-4 ">
                                    <button className=" btn-dark_blue rounded full  align-items-center d-flex" onClick={() => setRevisionOpen(true)}>
                                      <span className="me-5">add brief revision</span>
                                      <FontAwesomeIcon icon={faPlus as IconProp} style={{ fontSize: '2rem' }} className="text color-light" />
                                    </button>
                                  </div>
                                </div>
                                {revisionOpen && (
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row mb-5">
                                      <div className="col-12 col-md-3 text-16 fw-bold mb-3">Brief revision</div>
                                      <div className="col-12 col-md-9">
                                        <div className="col-12 d-flex justify-content-between mb-4  ">
                                          <div className="d-flex align-items-center">
                                            <span className="text-16">You can add up to 2 revisions to your brief</span>
                                          </div>
                                          <div className="d-flex justify-content-center align-items-center ">
                                            <span className="fw-bold"> Remove</span>
                                            <div
                                              onClick={() => setRevisionOpen(false)}
                                              className={`ms-3 rounded d-flex align-items-center justify-content-center ${styles.removeRevision}`}
                                            >
                                              <FontAwesomeIcon icon={faRemove as IconProp} className="text color-light" />
                                            </div>
                                          </div>
                                        </div>
                                        <textarea
                                          placeholder="Please write here the revision"
                                          {...register('revision', {
                                            onChange: handleRevisionLength,
                                            required: false,
                                            maxLength: 1000,
                                          })}
                                        ></textarea>
                                        <div className="row">
                                          <div className="col-10">
                                            <>
                                              {errors.revision ||
                                                (revisionError && (
                                                  <span className="text-xl color-danger">
                                                    <b>You have exceeded the limit of 1000 characters</b>
                                                  </span>
                                                ))}
                                            </>
                                          </div>
                                          <div className="col-2  d-flex justify-content-end">
                                            <span>{revisionLength}/1000</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3 "></div>
                                      <div className="col-2 ">
                                        <div className="">
                                          <button className="btn-dark-blue full submit  rounded">publish</button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                )}
                              </>
                            )}

                            <div className="row justify-content-center ">
                              <div className="col-10 col-md-4 ">
                                {computeContestPhase(contest) === 'upload-phase' ? (
                                  <button
                                    className="btn-success big full mb-4 rounded "
                                    style={{ height: '45px' }}
                                    onClick={() => (!loggedUser ? loginCallback() : redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_UPLOAD}`))}
                                  >
                                    <div className="btn-icon ">
                                      <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem' }} className="text color-light" />
                                    </div>
                                    <div>Upload</div>
                                  </button>
                                ) : (
                                  <button className="btn-dark_grey_90 big full mb-4 rounded " style={{ height: '45px', cursor: 'default' }} disabled={true}>
                                    <div className="btn-icon " style={{ cursor: 'default' }}>
                                      <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem', cursor: 'default' }} className="text color-light" />
                                    </div>
                                    <div>Upload</div>
                                  </button>
                                )}
                                <button
                                  className={`${isContestLiked(contest) ? 'btn-danger' : 'btn-blue'} rounded full mb-3 `}
                                  onClick={() => (loggedUser ? toggleContestLiked(contest) : loginCallback())}
                                >
                                  {isContestLiked(contest) ? 'Remove from favorites' : 'Add to favorites'}
                                </button>
                                {!contest.isClosed && (
                                  <button className="btn-blue rounded full mb-3" onClick={() => setPopupMessage(true)}>
                                    Have a question?
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="row justify-content-center  ">
                        <div className="col-10 col-md-4 ">
                          <button className="btn-success big full mb-4 rounded " style={{ height: '45px' }} onClick={loginCallback}>
                            <div className="btn-icon ">
                              <FontAwesomeIcon icon={faUserPlus as IconProp} style={{ fontSize: '2.5rem' }} className="text color-light" />
                            </div>
                            <div>Join desall </div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {contest.contestType === 'Co-creation' && (
                <>
                  <div className="row">
                    <div className="col-12 px-2 ">
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Contest typology</div>
                        <div className="col-12 col-md-9">
                          <span className="text-16">
                            <span className="color-blue me-2 cursor-pointer" onClick={() => redirect('/For_Creatives/How-to-participate')}>
                              Co-creation
                            </span>
                            contest
                          </span>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Company description</div>
                        <div className="col-12 col-md-9">
                          <span className="text-16">{contest.userDescr}</span>
                        </div>
                      </div>

                      {cocreationContests?.map((item: CoCreationContest, index: number) => (
                        <CoCreationBrief contest={item} key={index} />
                      ))}
                    </div>

                    {loggedUser ? (
                      <>
                        {/* <div className="row mb-5">
                        <div className="col-12 col-md-3 text fw-bold mb-3">Guidelines</div>
                        <div className="col-12 col-md-9">
                          <ReactMarkdown
                            children={finalString(contest.contestGuideline)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </div>
                      </div> */}

                        {contest.revision1 && (
                          <div className="row mb-5">
                            <div className="col-12 col-md-3 text fw-bold mb-3 d-flex">
                              Brief revision #1
                              <div className=" ms-4 bg-danger d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', height: '20px', width: '20px' }}>
                                <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                              </div>
                            </div>
                            <div className="col-12 col-md-9">
                              <p className="text">{revisionDateString(contest.revision1Date)}</p>
                              <span>
                                <ReactMarkdown children={finalString(contest.revision1)} remarkPlugins={[remarkGfm]} />
                              </span>
                            </div>
                          </div>
                        )}

                        {contest.revision2 && (
                          <>
                            <div className="row mb-5">
                              <div className="col-12 col-md-3 text fw-bold mb-3 d-flex">
                                Brief revision #2{' '}
                                <div className=" ms-4 bg-danger d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', height: '20px', width: '20px' }}>
                                  <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                                </div>
                              </div>
                              <div className="col-12 col-md-9">
                                <p className="text">{revisionDateString(contest.revision2Date)}</p>
                                <span>
                                  <ReactMarkdown children={finalString(contest.revision2)} remarkPlugins={[remarkGfm]} />
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        {loggedUser?.userType === 'C' && loggedUser.id === contest.userId && contest.contestType !== 'Co-creation' && contestrevision() && (
                          <>
                            <div className="row mb-5">
                              <div className="col-12 col-md-8"></div>
                              <div className="col-12 col-md-4 ">
                                <button className=" btn-dark_blue rounded full  align-items-center d-flex" onClick={() => setRevisionOpen(true)}>
                                  <span className="me-5">add brief revision</span>
                                  <FontAwesomeIcon icon={faPlus as IconProp} style={{ fontSize: '2rem' }} className="text color-light" />
                                </button>
                              </div>
                            </div>
                            {revisionOpen && (
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-5">
                                  <div className="col-12 col-md-3 text fw-bold mb-3">Brief revision</div>
                                  <div className="col-12 col-md-9">
                                    <div className="col-12 d-flex justify-content-between mb-4  ">
                                      <div className="d-flex align-items-center">
                                        <span className="text">You can add up to 2 revisions to your brief</span>
                                      </div>
                                      <div className="d-flex justify-content-center align-items-center ">
                                        <span className="fw-bold"> Remove</span>
                                        <div
                                          onClick={() => setRevisionOpen(false)}
                                          className={`ms-3 rounded d-flex align-items-center justify-content-center ${styles.removeRevision}`}
                                        >
                                          <FontAwesomeIcon icon={faRemove as IconProp} className="text color-light" />
                                        </div>
                                      </div>
                                    </div>
                                    <textarea
                                      placeholder="Please write here the revision"
                                      {...register('revision', {
                                        onChange: handleRevisionLength,
                                        required: false,
                                        maxLength: 1000,
                                      })}
                                    ></textarea>
                                    <div className="row">
                                      <div className="col-10">
                                        <>
                                          {errors.revision ||
                                            (revisionError && (
                                              <span className="text-xl color-danger">
                                                <b>You have exceeded the limit of 1000 characters</b>
                                              </span>
                                            ))}
                                        </>
                                      </div>
                                      <div className="col-2  d-flex justify-content-end">
                                        <span>{revisionLength}/1000</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3 "></div>
                                  <div className="col-2 ">
                                    <div className="">
                                      <button className="btn-dark-blue full submit  rounded">publish</button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}
                          </>
                        )}
                        {loggedUser?.userType === 'D' && (
                          <div className="row justify-content-center ">
                            <div className="col-10 col-md-4 ">
                              {contest && date < contest.endUploadDate && (
                                <button
                                  className="btn-success big full mb-4 rounded "
                                  style={{ height: '45px' }}
                                  onClick={() => (!loggedUser ? loginCallback() : redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_UPLOAD}`))}
                                >
                                  <div className="btn-icon ">
                                    <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem' }} className="text color-light" />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              )}
                              {contest && date > contest.endUploadDate && (
                                <button className="btn-dark_grey_90 big full mb-4 rounded " style={{ height: '45px', cursor: 'default' }} disabled={true}>
                                  <div className="btn-icon " style={{ cursor: 'default' }}>
                                    <FontAwesomeIcon icon={faCloudArrowUp as IconProp} style={{ fontSize: '3rem', cursor: 'default' }} className="text color-light" />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              )}
                              <button className={`${isContestLiked(contest) ? 'btn-danger' : 'btn-blue'} rounded full mb-4 `} onClick={() => toggleContestLiked(contest)}>
                                {isContestLiked(contest) ? 'Remove from favorites' : 'Add to favorites'}
                              </button>
                              {/* <button className="btn-blue rounded full mb-4">Add to favorite</button> */}
                              {!contest.isClosed && (
                                <button className="btn-blue rounded full mb-3" onClick={() => (loggedUser ? setPopupMessage(true) : loginCallback())}>
                                  Have a question?
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      contest.accessCode && (
                        <div className="row justify-content-center  ">
                          <div className="col-10 col-md-4 ">
                            <button className="btn-success big full mb-4 rounded " style={{ height: '45px' }} onClick={loginCallback}>
                              <div className="btn-icon ">
                                <FontAwesomeIcon icon={faUserPlus as IconProp} style={{ fontSize: '2.5rem' }} className="text color-light" />
                              </div>
                              <div>Join desall</div>
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default BriefWLF;
