import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import xLogo from '../../../assets/square-x-twitter_azure.png';

//Header Data
//Footer Data
//Styles
import styles from '../Profile.module.scss';

import { birthDateString, finalString } from '../../../helpers/utils';
import { useStoreState } from 'pullstate';
import DslStore, { setClientContest, setClientContestNumber } from '../../../store/DslStore';
import { Contest, ContestTag, Skill, SocialNetwork, User, UserDetails, UserFavorites } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpLogin from '../../../components/PopUp/PopUpLogin/PopUpLogin';
import PopUpSendMessage from '../../../components/PopUp/PopUpSendMessage/PopUpSendMessage';
import { joinedStrings, dateFromISOString } from '../../../helpers/stringUtils';
import SocialIcon from '../../../components/SocialIcon/SocialIcon';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ProfileColumn from './components/ProfileColumn';

interface ProfileProps {
  user: User;
  loggedUser: User | null;
  setBreadcrumbs: (breadcrumbs: string) => void;
  setPopupMessage: (message: boolean) => void;
}

const ProfileWLF: React.FC<ProfileProps> = ({ user, loggedUser, setPopupMessage }) => {
  const [popUpLogin, setPopupLogin] = useState(false);

  const setClientContests = async () => {
    let result = await axiosHelper.getContestsByClientId(user?.id);
    const clientContests: Contest[] = result.data;
    if (clientContests) {
      setClientContest(clientContests);
    }
  };

  const userContestNumber = () => {
    if (user) {
      let clientContestLength: number = user.contests.length;
      console.log(clientContestLength);
      setClientContestNumber(clientContestLength);
    }
  };

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  // useEffect(() => {
  //   if (blackList) {
  //     isInBlackList();
  //   }
  // }, [blackList]);

  const banUser = async () => {
    if (loggedUser?.userType === 'G' && user?.id) {
      await axiosHelper
        .banUser(user.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const removeFromBlackList = async () => {
  //   user?.id &&
  //     (await axiosHelper
  //       .removeUserFromBlackList(user.id)
  //       .then((response) => {
  //         console.log(response);
  //         setBlackList(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       }));
  // };

  // const addToBlackList = async () => {
  //   user?.id &&
  //     (await axiosHelper
  //       .addUserToBlackList(user.id)
  //       .then((response) => {
  //         console.log(response);
  //         setBlackList(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       }));
  // };

  // useEffect(() => {
  //   if (usersFavorites) {
  //     console.log('userfavoriteset');
  //     setFavoriteUsers(usersFavorites.usersILike);
  //   }
  // }, [usersFavorites]);

  // const userLiked = () => {
  //   if (user?.id && loggedUser) {
  //     loggedUser.id !== user.id &&
  //       axiosHelper.likeUser(user.id).then((response) => {
  //         setLiked(true);
  //         // alert(`${props.user?.username} added to favorites`);
  //       });
  //   }
  // };

  // const userUnliked = () => {
  //   if (user?.id) {
  //     axiosHelper.unlikeUser(user.id).then((response) => {
  //       setLiked(false);
  //       // alert(`${props.user?.username} removed from favorites`);
  //     });
  //   }
  // };

  // useEffect(() => {

  //   setUserDetails(user?.id);
  //   setClientContests();
  //   userFavorites();
  // }, [user]);

  // const isInBlackList = () => {
  //   let blacklist: boolean = false;
  //   if (user?.id) {
  //     blacklist = blackList.some((item) => item.id === user.id);
  //     setBlack(blacklist);
  //   }
  // };

  // useEffect(() => {
  //   let pathSplitted = path.split('/');

  //   axiosHelper.retrieveUserByName(pathSplitted[2])?.then((response) => {
  //     setCommunityUser(response.data);
  //   });

  //   loggedUser &&
  //     user &&
  //     loggedUser.id === user?.id &&
  //     redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser.nameForURL}/profile-editable`);
  // }, []);

  return (
    <div className="bg-light-gray px-md-0 ">
      <main className={`${styles.profileWrapper}  container pt-5 mb-5 px-sm-0  overflow-hidden `}>
        <div className={`row  `}>
          {/* Left column  ====================================================== */}
          <ProfileColumn user={user} />

          <div className="col-12 col-md-9 ">
            <div className="row">
              <div className="col-12 ps-4 ps-sm-5">
                {user.hasPublicName && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Name</div>
                    <div className="col-12 col-md-10">{joinedStrings(user.firstName, user.lastName)}</div>
                  </div>
                )}
                {user.companyName && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Company</div>
                    <div className="col-12 col-md-10">{user.companyName}</div>
                  </div>
                )}
                {user.sector && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Sector</div>
                    <div className="col-12 col-md-10">{user.sector}</div>
                  </div>
                )}

                {user.hasPublicBirthDate && user.birthDate && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">BirthDate</div>
                    <div className="col-12 col-md-10">{dateFromISOString(user.birthDate)}</div>
                  </div>
                )}
                {user.description && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Description</div>
                    <div className="col-12 col-md-10">{user.description}</div>
                  </div>
                )}
                {user.biography && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Biography</div>
                    <div className="col-12 col-md-10">
                      <p>{user.biography}</p>
                    </div>
                  </div>
                )}
                {/* {user?.userType === 'D' && (
                  <>
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                      {user.jobTitle && (
                        <div className="col-12 col-md-10">
                          {finalString(user?.jobTitle)},&nbsp; {finalString(user?.job?.name)}
                        </div>
                      )}
                    </div>
                  </>
                )} */}
                {user.school1 && user.hasPublicFirstSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{user?.school1}</div>
                  </div>
                )}
                {user?.school2 && user.hasPublicSecondSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{user?.school2}</div>
                  </div>
                )}
                {/* {user?.freelanceLicense && user.freelanceLicense !== 'N' && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Freelance license</div>
                    <div className="col-12 col-md-10">{user?.freelanceLicense === 'Y' ? 'yes' : 'no'}</div>
                  </div>
                )} */}

                {user.gender && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Gender</div>
                    <div className="col-12 col-md-10">{user.gender === 'M' ? 'He/Him' : user.gender === 'F' ? 'She/Her' : user.gender === 'N' ? 'N/A' : 'other'}</div>
                  </div>
                )}
                {user.socialNetworks && user.socialNetworks.length > 0 && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Social networks</div>
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      {user.socialNetworks.map((item: SocialNetwork, index: number) => (
                        <SocialIcon socialNetwork={item} key={index} />
                      ))}
                    </div>
                  </div>
                )}
                {user.website && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Website</div>
                    <div className="col-12 col-md-10">
                      <a href={user.website} target="_blank" rel="noreferrer">
                        {user.website}
                      </a>
                    </div>
                  </div>
                )}

                {/* <div className="row mb-5">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default ProfileWLF;
