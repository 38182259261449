import React, { useEffect, useRef, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import styles from './Brief.module.scss';

//Header Data

import {
  getCoCreationContestAwards,
  getCoCreationContestStats,
  getContestAwards,
  getContestTags,
  getCurrentContest,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTimelinePassedClientVote,
  getTimelineTotClientVote,
  getCoCreationContests,
  getAllCoCreationContests,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setContestTags, setContestsILike, setCurrentContest } from '../../store/DslStore';
import { fullPathLogoDesign, fullPathLogoUser, registrationDateString } from '../../helpers/utils';

import axiosHelper from '../../helpers/axiosHelper';

import { faFacebookF, faPinterest, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { AwardRow } from '../../components/Contest/AwardRow/AwardRow';
import { useNavigate, useParams } from 'react-router-dom';
import { Award, CoCreationContest, Contest, ContestTag, User } from '../../global';

import { NoContent } from '../../components/Reusable/NoContent';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import profileImg from '../../assets/Profile.png';
import designImg from '../../assets/Design.png';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

import xLogo from '../../assets/x-twitter-01.png';
import ContestSidePart from './ContestSidePart';
import { useAuth } from '../../context/AuthContext';

interface ContestProps {
  contest: Contest;
  loginCallback: () => void;
  setPopupMessage: (message: boolean) => void;
}

const AwardsWLF: React.FC<ContestProps> = (props) => {
  const { loggedUser: user } = useAuth();
  const cocreationcontests = useStoreState(DslStore, getCoCreationContests);
  const allcocreationcontests = useStoreState(DslStore, getAllCoCreationContests);
  // const contest = useStoreState(DslStore, getCurrentContest);
  // const contestAwards = useStoreState(DslStore, getContestAwards);
  const cocreationAwards = useStoreState(DslStore, getCoCreationContestAwards);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const redirect = useNavigate();
  const [popup, setPopup] = useState(false);
  const contestEndDate = useRef('');
  const endvotingdate = useRef('');
  const uploadActive = useRef(false);
  const path = window.location.href;

  useEffect(() => {
    console.log('WLF AWARDS');
  }, []);

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().toISOString();

    // console.log('GET CORRECT END DATE : ');
    if (props.contest) {
      if (props.contest?.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        allcocreationcontests.map((item) => {
          if (props.contest?.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          console.log(item);
          if (item.endVotingDate) {
            endvotingdate.current = item.endVotingDate;
          }
          if (!item.isClosed) {
            if (date > item.startUploadDate && date < item.endUploadDate) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends: ${registrationDateString(item.endUploadDate)}`;
              uploadActive.current = true;
              return;
            } else if (item.endVotingDate) {
              if (date > item.endUploadDate && date < item.endVotingDate) {
                // console.log('CO-CREATION COMMUNITY');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              } else if (date > item.endUploadDate && date > item.endVotingDate) {
                // console.log('CO-CREATION CLIENT');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              }
            } else if (date > item.endUploadDate) {
              // console.log('CO-CREATION CLIENT VOTE');
              contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
            }
          } else {
            // console.log('CO-CREATION CLOSED');
            contestEndDate.current = `Closed: ${registrationDateString(item.endUploadDate)}`;
          }
        });
      } else {
        if (props.contest.endVotingDate) {
          endvotingdate.current = props.contest.endVotingDate;
        }
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < props.contest.endUploadDate && !props.contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.endUploadDate)}`;
          uploadActive.current = true;
          return;
        } else if (props.contest.endVotingDate && date < props.contest.endVotingDate && !props.contest.isClosed) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.endUploadDate)}`;
          return;
        } else if (
          (props.contest.endVotingDate && date > props.contest.endVotingDate && !props.contest.isClosed) ||
          (date > props.contest.endUploadDate && !props.contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.hypotheticalEndDate)}`;
          return;
        } else {
          // console.log('GET CORRECT END DATE : CLOSED PHASE');
          contestEndDate.current = `CLOSED ${registrationDateString(props.contest.endUploadDate)}`;
          return;
        }
      }
      // return contest?.endUploadDate;
    }
  };

  useEffect(() => {
    // console.log('cambiati cocreation contests');
    getCorrectEndDate();
  }, [allcocreationcontests, props.contest?.name]);

  // useEffect(() => {
  //   console.log(popup);
  // }, [popup]);

  // useEffect(() => {
  //   // conta();
  //   // console.log(contest);

  //   props.contest &&
  //     props.contest.contestType !== 'Co-creation' &&
  //     setTimelines(contest.startDate, contest.endUploadDate, contest.endVotingDate, contest.hypotheticalEndDate, contest);
  //   // console.log(allCocreationContests);
  // }, []);

  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        {/* Body ====================================================================== */}
        <div className="row  ">
          <ContestSidePart contest={props.contest} loginCallback={props.loginCallback} setPopupMessage={props.setPopupMessage} />
          <div className="col-12 col-md-9  pt-5 px-5 px-sm-0">
            {user
              ? props.contest?.contestType !== 'Co-creation' &&
                props.contest.awards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4 px-4">
                    <AwardRow
                      key={index}
                      contest={props.contest}
                      contestAward={aContestAward}
                      image={
                        aContestAward.design
                          ? aContestAward.design.designer.logoAttachment
                            ? aContestAward.design.designer.logoAttachment.s3Path
                            : profileImg
                          : profileImg
                      }
                      designImage={
                        props.contest?.isPrivate
                          ? designImg
                          : aContestAward.design
                          ? aContestAward.design.image1Attachment
                            ? aContestAward.design.image1Attachment.s3Path
                            : designImg
                          : designImg
                      }
                    />
                  </div>
                ))
              : // <NoContent />
                props.contest?.contestType !== 'Co-creation' &&
                props.contest.awards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4 px-4">
                    {aContestAward.design ? (
                      <AwardRow
                        key={index}
                        contest={props.contest}
                        contestAward={aContestAward}
                        image={
                          aContestAward.design
                            ? aContestAward.design.designer.logoAttachment
                              ? aContestAward.design.designer.logoAttachment.s3Path
                              : profileImg
                            : profileImg
                        }
                        designImage={designImg}
                      />
                    ) : (
                      <AwardRow
                        key={index}
                        contest={props.contest}
                        contestAward={aContestAward}
                        image={fullPathLogoUser('')}
                        designImage={props.contest?.isPrivate ? fullPathLogoDesign('') : fullPathLogoDesign('')}
                      />
                    )}
                  </div>
                ))}
            {props.contest?.contestType === 'Co-creation' &&
              cocreationcontests.map((item: CoCreationContest) =>
                cocreationAwards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4  px-4">
                    {item.id === aContestAward.contest?.id
                      ? (console.log(aContestAward),
                        (
                          <>
                            <span className="text-16 ">
                              <b>
                                {item.contestType.name.toUpperCase()} - {aContestAward.pos}&deg; AWARD
                              </b>
                            </span>
                            <span className="pb-3 text">{aContestAward.name}</span>
                            {!item.isPrivate ? (
                              <AwardRow
                                key={index}
                                contest={props.contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={
                                  aContestAward.design
                                    ? aContestAward.design.image1Attachment
                                      ? aContestAward.design.image1Attachment.s3Path
                                      : designImg
                                    : designImg
                                }
                              />
                            ) : user ? (
                              <AwardRow
                                key={index}
                                contest={props.contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={
                                  aContestAward.design
                                    ? aContestAward.design.image1Attachment
                                      ? aContestAward.design.image1Attachment.s3Path
                                      : designImg
                                    : designImg
                                }
                              />
                            ) : (
                              <AwardRow
                                key={index}
                                contest={props.contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={designImg}
                              />
                            )}
                          </>
                        ))
                      : ''}
                  </div>
                ))
              )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AwardsWLF;
